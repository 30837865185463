import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  base: {},
  root: {    
    padding: theme.spacing.unit * 2
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: theme.spacing.unit * 3,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  pagetitle: {
    color:theme.palette.primary.main,
    paddingLeft: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 3
  },
  userlist:{
    paddingBottom: 30,
  },
  userlistitem: {
    borderTop: '1px solid #6d6f72',
    fontFamily: "Calibri, sans-serif",
    fontSize: 14,
    color: '#333333',
    padding: '11px 0'
  }
});

class UserActivity extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      value: 'true',
      checkedCreate: '',
      checkedEdit: '',
    };
  }

  handleBin = type => {}

  handleChange = (event, variable) => {
      this.setState({ value: event.target.value });
    };

    handleCheckboxChange = name => event => {
      this.setState({ [name]: event.target.checked });
    };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.base}>
        <Grid container component="main" className={classes.root}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className={classes.pagetitle}>
              User
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.root}>
                <Grid container className={classes.formcontainer}>
                  <Grid item xs={12} sm={6}>
                    <Grid container className={classes.row}>
                      <Grid item xs={12} sm={4} className={classes.label}>User</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name_of_sensor"
                            name="name"
                            value="John Doe"
                            disabled
                          />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Grid item xs={12} sm={4} className={classes.label}>Last Login</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name_of_sensor"
                            name="name"
                            value="01/09/2019"
                            disabled
                          />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Grid item xs={12} sm={4} className={classes.label}>Number of logins</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name_of_sensor"
                            name="name"
                            value="50"
                            disabled
                          />
                      </Grid>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Grid item xs={12} sm={4} className={classes.label}>Campaigns Created</Grid>
                      <Grid item xs={12} sm={8}>
                        <List className={classes.userlist}>
                          <ListItem className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Grid item xs={12} sm={4} className={classes.label}>Campaigns Viewed</Grid>
                      <Grid item xs={12} sm={8}>
                        <List className={classes.userlist}>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                          <ListItem  className={classes.userlistitem}>
                            <ListItemText primary="Campaign name goes here" disableTypography />
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

UserActivity.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UserActivity);
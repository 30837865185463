import React from 'react';
import { BrowserRouter, Link, Route, Switch, } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { LockOpen } from '@material-ui/icons';

import Logo from '../../assets/emblemhealth-logo.png';
import LoginBackground from '../../assets/login-illustration.png';
import API from '../../utils/API';
import AlertDialog from '../alerts/Alert';

const styles = theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${LoginBackground})`,
    backgroundColor: '#ebf4fb',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'center',
    backgroundPosition: 'center',
  },
  papercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width:'30%',
    marginBottom: theme.spacing.unit * 4,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 48,
    fontWeight: '700',
    marginBottom: theme.spacing.unit * 8
  },
  subTitle: {
    fontSize:30,
    fontWeight: '700',
    marginBottom: theme.spacing.unit * 4
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  formcontainer: {
    paddingBottom:'2rem',
    borderBottom: 'none',
    marginBottom: '2rem'
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: '12px',
    color: '#6d6f72'
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0),
    textTransform: 'initial',
    borderRadius: 20,
    minWidth: '100px'
  },
  forgotten: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500'
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  loginText: {
    backgroundColor: 'transparent',
  },
  requestText: {
    marginBottom: theme.spacing.unit * 2.5
  }
});

class ForgotPassword extends React.Component {
  // const classes = useStyles();
  state = {
    message: null,
    open: false,
    email: null
  }

  handleClose = () => {
    this.setState({'open': false});
  }

  handleForgotPassword = (event) => {
    event.preventDefault();
    let email = this.state.email;

    if (!email) {
      this.setState({
          'message': 'You must complete the form correctly',
          'open': true
        });
      return false;
    }
    this.forgotPassword(email);
    return false;
  }

  forgotPassword = (email) => {
    API.post('/forgot-password/', {
      email: email
    })
    .then((res) => {
      if (res.data.success) {
        this.setState({
          'message': 'Please check your email for instructions to reset your password',
          'open': true});
        return;
        }
        this.setState({
            'message': 'There was an undefined issue in handling this.',
            'open': true
          });

    })
    .catch((error) => {
      this.setState({
          'message': 'Cannot continue with the current details',
          'open': true
        });
    });
    
  }

  handleUpdate = (name, value) => {
    this.setState({[name]: value});
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleForgotPassword(e);
    }
  }
  render() {
    const { classes } = this.props;
    const { open, message } = this.state;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={6} md={6} className={classes.image} />
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square className={classes.papercontainer}>
          <div className={classes.paper}>
            <Grid container justify={'center'}>
              <Grid item xs={6}>
                <img src={Logo} className={classes.logo} alt="logo" />
                <Typography component="h2" variant="h1" className={classes.title}>
                    Emblem Health Administration
                </Typography>
                <Typography component="h2" variant="h2" className={classes.subTitle}>
                    Forgot password
                </Typography>
                <Typography component="p" variant="body1" className={classes.requestText}>
                    Please enter your email below.
                </Typography>
                <form className={classes.form} noValidate>
                  <Grid container className={classes.formcontainer}>
                      <Grid item xs={12} sm={12} className="formLabel">Email</Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            fullWidth
                            id="email"
                            name="email"
                            onKeyDown={this._handleKeyDown}
                            onChange={e => this.handleUpdate('email', e.target.value)}
                          />
                      </Grid>
                    </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Link to="/login" variant="body2" className={classes.forgotten}>
                        Login
                      </Link>
                    </Grid>
                    <Grid item xs align="right">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={this.handleForgotPassword}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>  
          </div>
        </Grid>
        <AlertDialog open={open} message={message} close={this.handleClose} />
      </Grid>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPassword);
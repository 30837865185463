import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppModal from "../components/Modals/AppModal"

// API
import API from "../utils/API";
import ProfileService from "../utils/ProfileService";

// ASSETS
import illustration from '../assets/dashboard-illustration.svg';


// COMPONENTS
import {Grid, Typography, Button, IconButton, Toolbar, Divider } from '@material-ui/core';

// import ProjectCard from '../components/Cards/ProjectCard';
import Pagination from 'material-ui-flat-pagination';
import Paper from '@material-ui/core/Paper';
// import Filter from '../components/Filter';
// import CreateProjectModal from '../components/Modals/CreateProjectModal';


// ICONS
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import NotificationIcon from '../assets/menu-notifications.png';

// IMAGES
import MedicalPlans from '../assets/menu-medical_plans.png';
import PharmacyPlans from '../assets/menu-pharmacy_plans.png';
import Plans from '../assets/menu-plans.png';
import SmallGroupPolicies from '../assets/menu-small_group_policies.png';
import Summaries from '../assets/menu-summaries.png';
import PlanModifications from '../assets/menu-plan_modifications.png';
import SummariesBig from '../assets/menu-summaries-big.png';

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit * 5.5,
    paddingBottom: theme.spacing.unit * 8.5
  },
  wrapper: {
    textAlign: 'center',
  },
  pageTitle: {
    color:theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 36,
    paddingTop: theme.spacing.unit*6,
    paddingBottom: theme.spacing.unit*6
  },
  paper: {
    height: '100%',
    minHeight: 220,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.only('lg')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
    },
  },
  iconContainer: {
    position: 'relative',
    textAlign: 'center',
    zIndex: 1,
    '&::before': {
      content: '" "',
      width:42,
      height:42,
      backgroundColor:'#e5f9fb',
      position: 'absolute',
      borderRadius: '50%',
      top:15,
      left:-10,
      zIndex: -1,
    }
  },
  iconContainerForNotification: {
    position: 'relative',
    color: '#3c5a9a',
    zIndex: 1,
    fontWeight: 700,
    '&::before': {
      content: '" "',
      width:19,
      height:19,
      backgroundColor:'#e5f9fb',
      position: 'absolute',
      borderRadius: '50%',
      top:3,
      left:-6,
      zIndex: -1,
    }
  },
  cardIcon: {
    color: theme.palette.primary.light,
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: 45,
    width: 'auto',
  },
  cardTitle: {
    color: theme.palette.primary.light,
    fontWeight: 700,
    fontSize: 36,
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 24,
    },
  },
  
  recordCount: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 700,
    marginBottom: theme.spacing.unit*6,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
  },
  cardButton: {
    color:theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    width: '100%',
    position: 'relative',
    borderRadius: 15,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    "&:hover": {
      backgroundColor: '#27498f',
      color: '#fff'
    }
  },
  cardButtonText: {
    [theme.breakpoints.only('xl')]: {
      fontSize: theme.spacing.unit * 2,
    },
    color: 'inherit',
    fontSize: '14px',
    float: 'left'
  },
  // viewButtonWrapper:{
  //   [theme.breakpoints.down('xl')]: {
  //     display: 'flex',
  //     justifyContent: "center"
  //   }
  // },
  viewButton:{
    color: theme.palette.primary.main,
    fontSize: '14px',
    letterSpacing: 0,
    '& label': {
      color: theme.palette.primary.main,

    },
    '& span': {
      // textAlign: 'right'
    },
    padding: '10px 0',
    [theme.breakpoints.only('xl')]: {
      fontSize: theme.spacing.unit * 2,
    },
  },
  addIcon: {
    color: theme.palette.primary.light,
    float: 'right',
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.spacing.unit * 2.4,
    },
    // position: 'absolute',
    // right: 4,
    // top: '50%',
    // transform: 'translateY(-50%)'
  },
  blue: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:`url(${SummariesBig })`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 110,
    backgroundPositionX: -40,
    backgroundPositionY: 20,
    '& $cardTitle': {
      color: theme.palette.common.white,
    },
    '& $recordCount': {
      color: theme.palette.primary.light
    },
    '& $viewButton':{
      color: theme.palette.common.white,
      '& label': {
      color: theme.palette.common.white,
    },
    },
    '& $cardButton': {
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& $addIcon': {
          color:theme.palette.common.white
        }
      } 
    },
  },
  notificationContainer: {
    paddingRight: theme.spacing.unit*4
  },
  notificationTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  notificationH3:{
    paddingTop: theme.spacing.unit*1,
    fontSize: '16px',
    color: theme.palette.primary.light,
    fontWeight: 'bold'
  },
  notificationDate:{
    paddingTop: theme.spacing.unit*1,    
    paddingBottom: theme.spacing.unit*1,
    textAlign: 'right',
    color: '#cdd0da',
    fontSize:14
  },
  notificationP: {
    color: theme.palette.primary.main
  },
  notificationBy: {
    color: '#cdd0da',
    fontSize:14
  },
  subNavWrapper: {
    marginBottom: theme.spacing.unit * 3,
  },
  subNavLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  subNavButton: {
    marginLeft: 'auto',
  },
  illustration: {
    display: 'block',
    margin: '0 auto 12px',
  },
  subTitle: {
    fontWeight: 300,
    marginBottom: 30,
  },
  newProjectButton: {
    borderRadius: 24,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  projectCard: {
    marginBottom: theme.spacing.unit * 4.25,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  pagination: {
    marginTop: theme.spacing.unit * 5
  },
  toolBar: {
    minHeight: 'auto',
    backgroundColor: '#2a0714',
    boxShadow: 'none',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    }
  },
  topContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 35
    }
  },
  bottomContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  filter: {
    marginLeft: theme.spacing.unit * 3,
  },
  filterLabel: {
    marginRight: theme.spacing.unit * 5,
    color: theme.palette.common.white,
  },
  logLink: {
    color: '#27498f',
    textDecoration: 'underline'
  }
});
let baseLink = "/dashboard/";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: {},
      modalOpen: "",
      modalMessage: "",
      isVendor: false,
      isNotEditor: false,
    };
    this.modal = React.createRef();
    setTimeout(() => this.getData());
  }

  getUrl = () => {
    let url = baseLink;
    return url;
  }

  async getData() {
    // Load async data.
    let url = this.getUrl();
    
    let apiData = await API.get(url)
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
    // Parse the results
    let item = apiData.data;

    const {isVendor, isNotEditor} = this.checkUserRole();
    
    // Set the clients
    this.setState({
      ...this.state, ...{
        item,
        isVendor,
        isNotEditor,
      }
    });
  }

  // TODO: Uncomment and amend once profile object has been updated to return user role
  checkUserRole = () => {
      let isVendor = false;
      let isNotEditor = false;
      let role = ProfileService.returnCurrentRole()

      if (role === 'Vendor') {
        isVendor = true;
        isNotEditor= true;
      }
      
      if (!role || role === 'Underwriter') {
        isNotEditor= true;
      }

      return {isVendor, isNotEditor};
  }

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  render() {
    const { classes } = this.props;
    const { item, isVendor, isNotEditor } = this.state;
    
    return (
      <React.Fragment>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h2" variant="h1" className={classes.pageTitle}>
              Dashboard
            </Typography>
          </Grid>
          <Grid item xs={12} xl={11}>
            <Grid container spacing={24}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={24}>
                  <Grid item xs={12} md={6}>
                    <Paper className = {classes.paper}>
                      <Grid container spacing={8} justify="space-between">
                        <Grid item xs={2}>
                          <div className={classes.iconContainer}>
                            <img src={Plans} className={classes.cardIcon}/> 
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography component="h2" variant="h2" className={classes.cardTitle}>
                            All Plans
                          </Typography>
                          <Typography component="h3" className={classes.recordCount}>
                            {item.plans} Records
                          </Typography>
                        </Grid>
                        </Grid>
                        <Grid container>
                        <Grid item xs={12} lg={5} xl={6}>
                          <Button component={Link} to="/plans" className={classes.viewButton}>View All Records</Button>
                        </Grid>
                        {
                          !isNotEditor &&
                          <Grid item xs={12} lg={7} xl={6}>
                            <IconButton component={Link} to="/plans/add" className={classes.cardButton}
                                        variant="contained">
                              <Typography component="p" className={classes.cardButtonText}>Add Plan</Typography>
                              <AddCircleIcon className={classes.addIcon}/>
                            </IconButton>
                          </Grid>
                        }
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className = {classes.paper}>
                      <Grid container spacing={8} justify="space-between">
                        <Grid item xs={2}>
                          <div className={classes.iconContainer}>
                            <img src={PlanModifications} className={classes.cardIcon}/> 
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography component="h2" variant="h2" className={classes.cardTitle}>
                            Plan Modifications
                          </Typography>
                          <Typography component="h3" className={classes.recordCount}>
                            {item.plan_modifications} Records
                          </Typography>
                        </Grid>
                        </Grid>
                        <Grid container>
                        <Grid item xs={12} lg={5} xl={6}>
                          <Button component={Link} to="/plan-modifications" className={classes.viewButton}>View All Records</Button>
                        </Grid>
                          {
                            isVendor &&
                            <Grid item xs={12} lg={7} xl={6}>
                              <IconButton component={Link} to="/plan-modifications/add" className={classes.cardButton}
                                          variant="contained">
                                <Typography component="p" className={classes.cardButtonText}>Request Modification</Typography>
                                <AddCircleIcon className={classes.addIcon}/>
                              </IconButton>
                            </Grid>
                          }

                        </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className = {classes.paper}>
                      <Grid container spacing={8} justify="space-between">
                        <Grid item xs={2}>
                          <div className={classes.iconContainer}>
                            <img src={PlanModifications} className={classes.cardIcon}/> 
                          </div>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography component="h2" variant="h2" className={classes.cardTitle}>
                            Templates
                          </Typography>
                          <Typography component="h3" className={classes.recordCount}>
                            {item.templates} Records
                          </Typography>
                        </Grid>
                        </Grid>
                        <Grid container>
                        <Grid item xs={12} xl={6}>
                          <Button component={Link} to="/templates" className={classes.viewButton}>View All Records</Button>
                        </Grid>
                        <Grid item xs={6}>
                        </Grid>
                      </Grid>  
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className = {`${classes.paper} ${classes.blue}`}>
                       <Grid container spacing={8}>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography component="h2" variant="h2" className={classes.cardTitle}>
                            Summaries
                          </Typography>
                          <Typography component="h3" className={classes.recordCount}>
                            {item.small_summaries} Records
                          </Typography>
                       </Grid>
                       </Grid>
                       <Grid container>
                         <Grid item xs={12} lg={5} xl={6}>
                            <Button component={Link} to="/small-summaries" className={classes.viewButton}>View All Records</Button>
                         </Grid>
                         <Grid item xs={12} lg={7} xl={6}>
                          <IconButton component={Link} to="/small-summaries/add" className={classes.cardButton} variant="contained"><Typography component="p" className={classes.cardButtonText}>Add Summary</Typography> <AddCircleIcon className={classes.addIcon}/></IconButton>
                        </Grid>
                        </Grid>  
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container>
                  <Grid item xs={12}>
                    {item.log_entries && 
                      <Paper className = {classes.paper}>
                         <Grid container style={{ marginBottom: 30 }}>
                          <Grid item xs={9}>
                            <Grid style={{display: 'flex', alignItems: 'center'}} className={classes.iconContainerForNotification}>
                              <img src={NotificationIcon} style ={{marginRight: 20, width: 23, height: 'auto'}} className={classes.cardIcon}/>
                                  Notifications
                            </Grid>
                          </Grid> 
                          <Grid item xs={3}>
                             {/* <Button className={classes.viewButton} style={{display: 'flex', justifyContent: 'flex-end'}}>View All</Button> */}
                          </Grid>
                        </Grid>
                        {item.log_entries.map((log_entry, idx) => 
                          <React.Fragment>
                            <Grid container >
                              <Grid item xs={9}>
                                <Typography component="h3" variant="h3" className={classes.notificationH3}>
                                  {log_entry.content_type}
                                </Typography>
                              </Grid> 
                              <Grid item xs={3} >
                                <Typography component="p"  className={classes.notificationDate}>
                                  {log_entry.action_time}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography component="p"  className={classes.notificationP}>
                                  <Link to={log_entry.url} className={classes.logLink}>{log_entry.title}</Link>: {log_entry.action_flag}
                                </Typography>
                                <Typography component="p"  className={classes.notificationBy}>
                                  By: {log_entry.user}
                                </Typography>
                              </Grid>
                            </Grid>    
                            <Divider />
                          </React.Fragment>
                        )}
                      </Paper>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  };
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Dashboard));
import React from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AutoComplete from '../Fields/AutoComplete';
import EditIcon from '@material-ui/icons/Edit';
import Collapse from '@material-ui/core/Collapse';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import TimeSchedulingForm from './TimeSchedulingForm';
import AdvancedCalendarViewForm from './AdvancedCalendarView';

import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import '../../assets/customdatepickerstyle.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const styles = theme => ({
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  label:  {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  build: {
    marginRight: 15,
    height: 22,
    width: 'auto', 
    color: '#445f6d',
  },
  transButton: {
    padding: 10,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontSize: 14,
    borderRadius: 5,
    fontWeight: 500,
    fontFamily: "Calibri, sans-serif",
  },
});

const inputstyles = {
  style: {
    fontSize: '14px',
    color: '#333',
    padding: '0 10px',
    backgroundColor: '#e2eef6',
    height: 40,
    borderRadius:3,
    fontFamily: "Calibri, sans-serif",
    border: 0,
    width: '100%',
    '&:focus': {
      backgroundColor: 'rgba(53, 57, 68, .03)'
    }
  } 
}

  const modifiersStyles = {
    caption: {
      fontFamily: "Calibri, sans-serif",
    }
  };

class CalendarForm extends React.Component {
  // const classes = useStyles();

  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
      containerVisible: false,
      advancedCalendarView: false
    };
  }

  showFromMonth() {
    const { from, to } = this.state;
    if (!from) {
      return;
    }
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    this.setState({ from });
  }

  handleToChange(to) {
    this.setState({ to }, this.showFromMonth);
  }

  handleContainerVisible(event) {
    this.setState({containerVisible: !this.state.containerVisible})
  }

  handleAdvancedCustomisation(e) {
    e.preventDefault();
    return false;
  }

  handleAdvancedCustomisationClosed() {
    this.switchAdvancedCalendarView();
  }
  switchAdvancedCalendarView() {
    let advancedCalendarView = this.state.advancedCalendarView;
    this.setState({advancedCalendarView: !advancedCalendarView});
  }
  render() {
    const { classes } = this.props;
    const { calendar, from, to, containerVisible, advancedCalendarView } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <React.Fragment>
        {!advancedCalendarView && 
        <Grid container component="main" className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="h2" gutterBottom>Scheduling event</Typography>
            <Grid container >
              <Grid item xs={12} sm={8} >
                <div className={classes.InputFromTo}>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={4} className={classes.label}>Scheduling event</Grid>
                  <Grid item xs={12} sm={4}>
                    <DayPickerInput
                      value={from}
                      placeholder="From"
                      format="LL"
                      formatDate={formatDate}
                      parseDate={parseDate}
                      dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        numberOfMonths: 2,
                        onDayClick: () => this.to.getInput().focus(),
                      }}
                      onDayChange={this.handleFromChange}
                      inputProps={inputstyles} 
                      modifiersStyles={modifiersStyles}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <span className="InputFromTo-to">
                      <DayPickerInput
                        ref={el => (this.to = el)}
                        value={to}
                        placeholder="To"
                        format="LL"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                          selectedDays: [from, { from, to }],
                          disabledDays: { before: from },
                          modifiers,
                          month: from,
                          fromMonth: from,
                          numberOfMonths: 2,
                        }}
                        onDayChange={this.handleToChange}
                        inputProps={inputstyles} 
                        modifiersStyles={modifiersStyles}
                      />
                    </span>
                  </Grid>
                </Grid>
              </div>
                <Grid container spacing={8}>
                  <Grid item xs={12} sm={4} className={classes.label}>Scheduling event</Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoComplete list="time" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoComplete list="time" />
                  </Grid>
                </Grid> 
                <Grid container>
                  <Grid item xs={12} sm={4}></Grid>
                  <Grid item xs={12} sm={6}>
                    <IconButton className={classes.transButton} aria-controls="customized-menu" aria-haspopup="true" onClick={e => this.handleContainerVisible(e)}>
                      <EditIcon className={classes.build} /> 
                      {!containerVisible && 'Advanced time scheduling'} 
                      {containerVisible && 'Close time scheduling'}
                    </IconButton>
                  </Grid>  
                </Grid> 
              </Grid>
              <Grid item xs={12}>
                <Collapse in={containerVisible} timeout="auto" unmountOnExit>
                  <Grid container >
                    <TimeSchedulingForm showAdvancedCalendarView={(e) => this.switchAdvancedCalendarView(e)} closeContainer={(e) => this.handleContainerVisible(e)} />
                  </Grid> 
                </Collapse>
              </Grid>
            </Grid>
                    
                    <Helmet>
                      <style>{`
 
              .InputFromTo .DayPicker-Day {
                border-radius: 0 !important;
              }
              .InputFromTo .DayPicker-Day--start {
                border-top-left-radius: 50% !important;
                border-bottom-left-radius: 50% !important;
              }
              .InputFromTo .DayPicker-Day--end {
                border-top-right-radius: 50% !important;
                border-bottom-right-radius: 50% !important;
              }
              .InputFromTo .DayPickerInput-Overlay {
                width: 550px;
              }
              .InputFromTo-to .DayPickerInput-Overlay {
                margin-left: -198px;
              }
            `}</style>
                    </Helmet>
          </Grid>
        </Grid>
        }
        {advancedCalendarView && 
          <AdvancedCalendarViewForm close={this.handleAdvancedCustomisationClosed.bind(this)} />
        }
      </React.Fragment>
    );
  }
}

CalendarForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CalendarForm);
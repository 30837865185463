import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// API
import API from "../../utils/API";

// ASSETS
import illustration from '../../assets/dashboard-illustration.svg';
import AdminHistoryTable from '../../components/Fields/AdminHistoryTable';
import AppModal from "../../components/Modals/AppModal"

// COMPONENTS
import { Grid, Typography, Button, IconButton, Link, Toolbar, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
// import ProjectCard from '../components/Cards/ProjectCard';
import Pagination from 'material-ui-flat-pagination';
import Paper from '@material-ui/core/Paper';
// import Filter from '../components/Filter';
// import CreateProjectModal from '../components/Modals/CreateProjectModal';

import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing.unit * 5.5,
    paddingBottom: theme.spacing.unit * 8.5
  },
  wrapper: {
    textAlign: 'center',
  },
  subNavWrapper: {
    marginBottom: theme.spacing.unit * 3,
  },
  subNavLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  subNavButton: {
    marginLeft: 'auto',
  },
  illustration: {
    display: 'block',
    margin: '0 auto 12px',
  },
  subTitle: {
    fontWeight: 300,
    marginBottom: 30,
  },
  newProjectButton: {
    borderRadius: 24,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  projectCard: {
    marginBottom: theme.spacing.unit * 4.25,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  pagination: {
    marginTop: theme.spacing.unit * 5
  },
  toolBar: {
    minHeight: 'auto',
    backgroundColor: '#2a0714',
    boxShadow: 'none',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    }
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1
  },
  topContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 35
    }
  },
  bottomContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  filter: {
    marginLeft: theme.spacing.unit * 3,
  },
  filterLabel: {
    marginRight: theme.spacing.unit * 5,
    color: theme.palette.common.white,
  },
  pageTitle: {
    color:theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 36,
    paddingTop: theme.spacing.unit*6,
    paddingBottom: theme.spacing.unit*6
  },
  buttonIcon: {
    marginLeft: 15,
  },
});

class IntroductionHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      archived: 0,
      page: 1,
      clients: [],
      ordering: 'latest',
      tool: null,
      campaignsTotal: null,
      campaigns: null,
      modalOpen: "",
      modalMessage: ""
    };
    this.modal = React.createRef();
  }

  async getProjects() {
    // Load async data.
    // let projectsData = await API.get('/account/jbcole/projects/', {
    //   params: {
    //     a: this.state.archived,
    //     p: this.state.page,
    //     c: this.state.clients,
    //     o: this.state.ordering,
    //     t: this.state.tool,
    //   }
    // })
    // .catch((error) => {
    //   alert('Error: ' + error.response.data.error_message);
    // });

    // // Parse the results
    // let projectsTotal = projectsData.data.total;
    // projectsData = projectsData.data.projects;

    let campaignData = [{
          'name': 'Campaign',
          'id': '99999999-9999-9999-9999-999999999999'
        }],
        campaignsTotal = 100
    // Set the projects
    this.setState({
      ...this.state, ...{
        campaignsTotal: campaignsTotal,
        campaigns: campaignData,
      }
    });
  }

  // Sets the client state for filter
  setClientsFilter = (clients) => {
    this.setState({
      clients: clients
    }, this.getProjects);
  }

  // Sets the tool state for filter
  setToolFilter = (tool) => {
    this.setState({
      tool: tool
    }, this.getProjects);
  }

  // Sets the sort state for filter
  setSortFilter = (sort) => {
    this.setState({
      ordering: sort
    }, this.getProjects);
  }

  // Toggles the archive
  toggleArchived = () => {
    let currentState = this.state.archived;
    this.setState({
      archived: currentState ? 0 : 1,
    }, this.getProjects);
  };
  
  // Handles the pagination
  handlePaginationClick(e, offset, page) {
    this.setState({
      offset: offset,
      page: page
    }, this.getProjects);
  }

  // Opens the modal window to create a new project
  handleNewCampaignClick = () => {
    this.setState(state => ({
      modalOpen: true,
      modalMessage: "Clicking a new campaign!"
    }))
  };

  handleProjectsRefresh = () => {
    this.getProjects();
  };

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  render() {
    const { classes } = this.props;
    const { campaignsTotal, campaigns, archived } = this.state;
    
    return (
      <React.Fragment>
        <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={8}>
            <Typography component="h2" variant="h1" className={classes.pageTitle}>
              Introduction
              <KeyboardArrowUpIcon className={classes.dropdownIcon}/>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} align="right">
            <IconButton href="/introduction" className="blueButton">
              Back <AddIcon className={classes.buttonIcon} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <AdminHistoryTable />
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  componentDidMount() {
    this.getProjects();
  }
}

IntroductionHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(IntroductionHistory));
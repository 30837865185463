import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

// API
import API from "../utils/API";

// ASSETS
import illustration from '../assets/dashboard-illustration.svg';

// COMPONENTS
import { Grid, Typography, Button, Link, Paper, Toolbar, TextField } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputBase from '@material-ui/core/InputBase';
import AddIcon from '@material-ui/icons/Add';
import Pagination from 'material-ui-flat-pagination';

import FaqList from '../components/Support/FaqList';
import DownloadList from '../components/Support/DownloadList';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';


const styles = theme => ({
  base: {
  },
  activeButton: {
    textAlign: 'right'
  },
  root: {
    flexGrow: 1, 
    padding: theme.spacing.unit * 2
  },
  wrapper: {
    textAlign: 'center',
  },
  pagetitle: {
    color:theme.palette.primary.main,
    paddingLeft: theme.spacing.unit * 2
  },
  primaryTable: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2
  },
  subNavWrapper: {
    marginBottom: theme.spacing.unit * 3,
  },
  subNavLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1,
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  subNavButton: {
    marginLeft: 'auto',
  },
  illustration: {
    display: 'block',
    margin: '0 auto 12px',
  },
  subTitle: {
    fontWeight: 300,
    marginBottom: 30,
  },
  newProjectButton: {
    borderRadius: 24,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  projectCard: {
    marginBottom: theme.spacing.unit * 4.25,
    [theme.breakpoints.down('sm')]: {marginBottom: 0}
  },
  h2support: {
    color: theme.palette.primary.main,
  },
  pagination: {
    marginTop: theme.spacing.unit * 5
  },
  toolBar: {
    minHeight: 'auto',
    backgroundColor: '#2a0714',
    boxShadow: 'none',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 30,
      paddingBottom: 30,
    }
  },
  topContainer: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 35
    }
  },
  bottomContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  filter: {
    marginLeft: theme.spacing.unit * 3,
  },
  filterLabel: {
    marginRight: theme.spacing.unit * 5,
    color: theme.palette.common.white,
  },
  input: {
    marginLeft: theme.spacing.unit,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  bluebox: {
    backgroundColor: '#e2eef6',
    padding: theme.spacing.unit*2,
    borderRadius: 5
  }
});

class Support extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      archived: 0,
      page: 1,
      clients: [],
      ordering: 'latest',
      tool: null,
      eventsTotal: null,
      events: null,
    };
    this.createEventModal = React.createRef();
    this.modal = React.createRef();
  }

  async getProjects() {
    // Load async data.
    // let projectsData = await API.get('/account/jbcole/projects/', {
    //   params: {
    //     a: this.state.archived,
    //     p: this.state.page,
    //     c: this.state.clients,
    //     o: this.state.ordering,
    //     t: this.state.tool,
    //   }
    // })
    // .catch((error) => {
    //   alert('Error: ' + error.response.data.error_message);
    // });

    // // Parse the results
    // let projectsTotal = projectsData.data.total;
    // projectsData = projectsData.data.projects;

    let campaignData = [{
          'name': 'Campaign',
          'id': '99999999-9999-9999-9999-999999999999'
        }],
        eventsTotal = 100
    // Set the projects
    this.setState({
      ...this.state, ...{
        eventsTotal: eventsTotal,
        events: campaignData,
      }
    });
  }

  // Sets the client state for filter
  setClientsFilter = (clients) => {
    this.setState({
      clients: clients
    }, this.getProjects);
  }

  // Sets the tool state for filter
  setToolFilter = (tool) => {
    this.setState({
      tool: tool
    }, this.getProjects);
  }

  // Sets the sort state for filter
  setSortFilter = (sort) => {
    this.setState({
      ordering: sort
    }, this.getProjects);
  }

  // Toggles the archive
  toggleArchived = () => {
    let currentState = this.state.archived;
    this.setState({
      archived: currentState ? 0 : 1,
    }, this.getProjects);
  };
  
  // Handles the pagination
  handlePaginationClick(e, offset, page) {
    this.setState({
      offset: offset,
      page: page
    }, this.getProjects);
  }

  // Opens the modal window to create a new project
  handleNewCampaignClick = () => {
    this.createEventModal.current.handleClickOpen();
  };

  handleProjectsRefresh = () => {
    this.getProjects();
  };

  render() {
    const { classes } = this.props;
    const { eventsTotal, events, archived } = this.state;
    
    return (
      <div className={classes.root}>
      <Grid container alignItems={'center'} justify={'space-between'}>
        <Grid item xs={9}>
          <Typography component="h2" variant="h1" gutterBottom className={classes.pagetitle}>
            Support
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems={'center'} justify={'space-between'}>
        <Grid item xs={12} lg={10} xl={8}>
          <Paper className={classes.primaryTable}>
            <Grid container alignItems={'center'} spacing={24}>
              <Grid item xs={12}>
                <Typography component="h2" variant="h2" className={classes.h2support}>
                  Introduction
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/pvkE_uNpcbU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h2" variant="h2" className={classes.h2support}>
                  Frequently Asked Questions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FaqList></FaqList>
              </Grid>
              <Grid item xs={12}>    
                <Typography component="h2" variant="h2" className={classes.h2support}>
                  Downloads
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DownloadList></DownloadList>
              </Grid>
              <Grid item xs={12} >
                <div className={classes.bluebox}>
                  <Typography component="h2" variant="h2" gutterBottom className={classes.h2support}>
                    Get in touch
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <EditOutlinedIcon /> Contact us for more information
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      </div>
    )
  };

  componentDidMount() {
    this.getProjects();
  }
}

Support.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Support));
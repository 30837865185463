import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AppModal from "../../components/Modals/AppModal"

import AutoComplete from '../../components/Fields/AutoComplete';
import DuplicateIcon from '../../assets/icon-duplicate.png';
import DeleteIcon from '../../assets/icon-delete.png';

import AddBoxIcon from '@material-ui/icons/AddBox';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import MDeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Tooltip from '@material-ui/core/Tooltip';

const grid = 8;

const styles = theme => ({
  base: {},
  root: {
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paperContainer: {
    position: 'relative'
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  toolbarIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing.unit*2
  },
  extendedColumn: {
    width: '30%',
    maxWidth: '30%',
    flexBasis: '30%'
  },
  minifiedColumn: {
    width: '10%',
    maxWidth: '10%',
    flexBasis: '10%'
  },
  minifiedToolbar: {
    marginRight: '6px'
  }
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  display: "inline-flex",
  width: "100%",
  // change background colour if dragging
  background: isDragging ? "rgba(0, 0, 0, 0)" : "white",
  display: "inline-flex",
  padding: "10px",
  borderRadius: 5,
  margin: "0 10px 10px 0",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" :"transparent",
  padding: grid,
  margin: "10px 0"
});

class InlineBenefitTable extends React.Component {
  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  constructor(props) {
    super(props);
    this.state = {
      options: {
        'actions': ['duplicate','delete','move'],
      }
    };
  }
  handleAction = (action, subItemId, parentId) => {
    this.props.handleBenefitRowAction(action, subItemId, parentId);
  }
  handleAutoCompleteChange = (key, value, subItemId, parentId) => {
    this.props.handleBenefitRowAutoCompleteChange(key, value, subItemId, parentId);
  }
  handleUpdate = (key, value, subItemId, parentId) => {
    this.props.handleBenefitRowUpdate(key, value, subItemId, parentId);
  }
  render() {
    const { classes } = this.props;
    const { options } = this.state;
    return (
      <Droppable droppableId={this.props.type} type={`droppableSubItem`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {this.props.benefits.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div style={{ display: "flex" }}>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Grid 
                      container
                      spacing={8}
                      alignItems="center"
                      >
                        <Grid item xs={3} sm={3} className={classes.extendedColumn}>
                          <Typography
                              style={{marginBottom:'5px'}}
                          >BENEFIT</Typography>
                          <AutoComplete type='benefits' value={item.benefit_id} update={(value) => this.handleAutoCompleteChange('benefit_id', value, item.id, this.props.type) } />
                        </Grid>
                        <Grid item xs={3} sm={3} className={classes.extendedColumn}>
                          <Typography
                          style={{marginBottom:'5px'}}
                          >
                            LABEL
                          </Typography>
                          <TextField
                            required
                            fullWidth
                            id="label"
                            name="label"
                            value={item.label}
                            onChange={e => this.handleUpdate('label', e.target.value, item.id, this.props.type)}
                            />
                        </Grid>
                        <Grid item xs={3} sm={3} className={classes.extendedColumn}>
                          <Typography
                          style={{marginBottom:'5px'}}
                          >
                            OUT 
                          </Typography>
                          {item.benefit_out && 
                            <AutoComplete type='benefits' value={item.benefit_out} update={(value) => this.handleAutoCompleteChange('benefit_out', value, item.id, this.props.type) } />
                          }
                          {!item.benefit_out && 
                            <TextField
                              disabled
                              fullWidth
                              placeholder='Please select benefit'
                              />
                          }
                        </Grid>
                        <Grid item xs={3} style={{marginTop: '27.4px'}} className={classes.minifiedColumn}>
                          {options.actions.map((action, idx) => {
                            let actionText = "Duplicate";
                            switch(action) {
                              case "delete":
                                actionText = "Delete";
                            }
                            return (
                              <Tooltip title={actionText}>
                                <IconButton style={options && !options.tabs? {padding: '12px 0'}: null} onClick={(e) => this.handleAction(action, item.id, this.props.type)}  className={classes.minifiedToolbar}>
                                  {action == 'duplicate' &&
                                     <FileCopyIcon className={classes.toolbarIcon}/>
                                  }
                                  {action == 'delete' &&
                              <MDeleteIcon className={classes.toolbarIcon}/>
                                  }
                                </IconButton>
                              </Tooltip>
                            )}
                            )}
                            <Tooltip title="Move">
                              <IconButton style={{padding: '12px 0'}} {...provided.dragHandleProps}  className={classes.minifiedToolbar}>
                                <OpenWithIcon className={classes.toolbarIcon}/>
                              </IconButton>
                            </Tooltip>
                        </Grid>
                      </Grid>
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default withStyles(styles)(InlineBenefitTable);
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select, {components as selectComponents} from 'react-select';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import {emphasize} from '@material-ui/core/styles/colorManipulator';

// API
import API from "../../utils/API";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AsyncSelect from "react-select/async/dist/react-select.esm";

const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: 250,
  },
  input: {
    display: 'flex',
    height: 40,
    padding: '0 10px',
    fontSize: 14,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  menuStyles: {
    fontSize: 12
  },
  noOptionsMessage: {
    padding: theme.spacing.unit * 2,
  },
  singleValue: {
    fontSize: 12,
  },
  placeholder: {},
  paper: {
    position: 'fixed',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 'auto',
    right: 'auto',
    fontFamily: "Calibri, sans-serif",
    fontSize: 14,
    width: 370,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  chip: {
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2,
    fontFamily: "Calibri, sans-serif",
    fontSize: 14,
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08,),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize: 14,
        fontFamily: "Calibri, sans-serif",
        color: props.isSelected ? '#fff' : '#1b4059',
        padding: 5,
        width: 1000
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <div
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
}

function SingleValue(props) {
  return (
    <div className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </div>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const DropdownIndicator = (innerProps) => {
  return (
    <selectComponents.DropdownIndicator {...innerProps}>
      <ArrowDropDownIcon/>
    </selectComponents.DropdownIndicator>
  );
}

const CustomOption = ({innerProps, children}) => {
  return (
    <MenuItem {...innerProps}>{children}</MenuItem>
  )
}

const selectStyles = {
  input: base => ({
    ...base,
    maxHeight: 200,
    '& input': {
      font: 'inherit',
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #e7edee',
    backgroundColor: '#f2f7f8',
    borderRadius: '3px',
    height: '42px',
    '&:hover': {
      boxShadow: 'none',
      outline: 'none'
    },
  }),
  menu: (base) => ({
    ...base,
    width: 'auto',
    minWidth: '100%'
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '18px',
    fontFamily: 'Calibri sans-serif'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#27498f',
    fontSize: '18px',
    fontFamily: 'Calibri sans-serif'
  }),
  dropdownIndicator: base => ({
    color: 'rgba(0, 0, 0, 0.54)'
  })
};

class AutoComplete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      single: props.value,
      multi: null,
      suggestions: [],
      placeholder: 'Choose a plan to modify...',
      selectRef: null
    };
  };

  handleChange = (value) => {
    this.setState({single: value});
    if (value) {
      this.props.update(value.value);
    } else {
      this.props.update(value);
    }
  }

  getUrl = (inputValue) => {
    let url = this.props.type;
    let queryList = [];
    if (inputValue) {
      queryList.push("n=" + inputValue);
    }
    if (queryList.length) {
      url += "?" + queryList.join("&");
    }
    return url;
  }

  loadOptions = (inputValue, callback) => {
    let url = this.getUrl(inputValue);
    console.log(url)
    if (this.state.suggestions.length) {
      return callback(this.state.suggestions.filter((obj) => {
        return obj.Name.indexOf(inputValue) >= 0
      }));
    }
    API.get(url)
      .then((res) => {
        let suggestions = res.data.items.map(suggestion => ({
          value: suggestion,
          label: suggestion.Name,
        }))
        if (this.state.single && typeof (this.state.single) != 'object') {
          for (let suggestion of res.data.items) {
            if (suggestion.value == this.state.single) {
              this.state.single = suggestion;
              this.setState({single: suggestion}, callback(suggestions));
              return;
            }
          }
        }
        callback(suggestions);
      });
  };

  render() {
    const {classes} = this.props;
    const {placeholder} = this.state;

    return (
      <div className={classes.root}>
        <AsyncSelect
          ref={el => this.selectRef = el}
          loadOptions={this.loadOptions}
          defaultOptions
          onChange={this.handleChange}
          className={classes.summarySelect}
          classes={classes}
          styles={selectStyles}
          value={this.state.single}
          components={{DropdownIndicator, Option: CustomOption}}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(AutoComplete);
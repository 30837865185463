import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconHistory from '../../assets/icon-history.png'
import CheckCircleOutlineIcon from '../../assets/icon-check-circle.png';
import CheckIcon from '../../assets/icon-check.png';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput, Divider } from '@material-ui/core';
import AppModal from "../../components/Modals/AppModal"

import AccountService from '../../utils/AccountService';

import EditToolbar from '../../components/Fields/EditToolbar';
import PdfModal from '../../components/Modals/PdfModal';
import TabForm from '../../components/Forms/TabForm';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

// API
import API from "../../utils/API";
import ProfileService from "../../utils/ProfileService";

function TabContainer(props) {
  return (
      props.children
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  base: {},
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paperScroll: {
    overflow: 'scroll'
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  styleForRightBorder: {
    borderRight: '1px solid #e7edee'
  },

  styleForLeftBorder: {
    borderLeft: '1px solid #e7edee'
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  textarea: {
    padding: 0,
    maxWidth: 120
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  paper: {
    position: 'relative'
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,
    paddingBottom: theme.spacing.unit*1,
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit*0.5,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  planModBtn: {
    width: 235,
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
  benefitTableHead: {
    backgroundColor: theme.palette.background.default,
    '& tr': {
      '& th': {
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        fontWeight: 600
      }
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  checkIcon: {
    width: 18,
    height: 'auto'
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  summarySelect: {
    width: '100%',
    height: 40,
  },
});

let baseLink = "/plans/";

class PlanItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    let item = null,
        actions = ['save'],
        redirectLink = this.setupParamsBasedOnLocation();
    if (this.props.match.params.id) {
      item = null;
      if (window.location.href.indexOf("add") < 0) {
        actions = actions.concat(['previewPdf','duplicate','delete']);
      }
    }
    this.state = {
      currentTab: 0,
      // handling PDF
      openPdf: false,
      pdfUrl: null,
      modalOpen: false,
      modalMessage: '',
      value: 'true',
      edit: false,
      isExpanded: false,
      currentCellId: '',
      currentCellTitle: '',
      editedText: '',
      checkedBenefits: [],
      fieldObj: {},
      isSaving: false,
      redirectLink: redirectLink,
      isVendor: false,
      isNotEditor: false,
      data:[
        {
        id: 1,
        lang: 'Cost Sharing Does',
        IN: 'No Charge',
        IN2: 'No Charge',
        IN3: 'In3',
        IN4: 'In4',
        OUT: 'No Change',
        OUT2: 'Default Text'
        },
        {
          id: 2,
          lang: 'Cost Sharing Does',
          IN: 'No Charge',
          IN2: 'No Charge',
          IN3: 'In3',
          IN4: 'In4',
          OUT: 'No Change',
          OUT2: 'Default Text'
        }
      ],
      item,
      options: {
        'actions': actions,
        'tabs': [
          // 'General Info',
          // 'Comments',
          // 'Others',
          // 'Coinsurance benefits',
          // 'Copays benefits',
          // 'Deductible benefits',
         /* 'Mail order benefits',
          'Retail benefits',
           'Rx deductible benefits',
          'Av deductible benefits',
           'Benefit headers',
          'SBCs-Misc Information',
          'SBCs-Important Questions: Mandated Text',
          'SBCs-Services Covered/NOT Covered',
          'SBCs-Example Section: General Costs',
          'SBCs-Example Section: Patient Pays',
          'SBCs-Minimum Coverage'*/
        ]
      }
    };
    if (!item) {
      setTimeout(() => this.getData());
    }
  }

  toggleExpand = () => {
    this.setState({isExpanded: !this.state.isExpanded});
  }

  setupParamsBasedOnLocation = () => {
    let path = window.location.pathname;
    // if (path.indexOf("medical") >= 0) {
    //   return "/medical-plans/";
    // }
    // else if (path.indexOf("pharmacy") >= 0) {
    //   return "/pharmacy-plans/";
    // }
    // else if (path.indexOf("small") >= 0) {
    //   return "/small-plans/";
    // }
    return "/plans/";
  }

  getUrl = (isSaving) => {
    let url = baseLink;
    if ((isSaving && window.location.href.indexOf("add") >= 0)) {
      return url;
    }
    if (window.location.href.indexOf("add") >= 0) {
      url += "add/";
    }
    if (this.props.match.params.id) {
      url += this.props.match.params.id;
    }
    return url;
  }

  async getData() {
    // Load async data.
    let url = this.getUrl();

    let apiData = await API.get(url)
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
    // Parse the results
    let item = apiData.data.item,
        benefits = apiData.data.benefits,
        tabs = apiData.data.tabs,
        form_fields = apiData.data.form_fields,
        fieldObj = apiData.data.field_obj,
        options = this.state.options;

    options.tabs = tabs;

    const {isVendor, isNotEditor} = this.checkUserRole();

    // Set the clients
    this.setState({
      ...this.state, ...{
        item,
        benefits,
        options,
        form_fields,
        fieldObj,
        isVendor,
        isNotEditor,
      }
    });
  }

  // TODO: Uncomment and amend once profile object has been updated to return user role
  checkUserRole = () => {
      let isVendor = false;
      let isNotEditor = false;
      let role = ProfileService.returnCurrentRole()

      if (role === 'Vendor') {
        isVendor = true;
        isNotEditor= true;
      }

      if (!role || role === 'Underwriter') {
        isNotEditor= true;
      }

      return {isVendor, isNotEditor};
  }

  handleChange = event => {
      this.setState({ [event.target.name]: event.target.value, edit: true });
    };

  updateCurrentTab = (currentTab) => {
    this.setState({ currentTab })
  }

  getFormFields = (tab) => {
    let { form_fields } = this.state,
        fields = [];
    for (let formField of form_fields) {
      if (formField[0] == tab) {
        fields = formField;
      }
    }
    return fields;
  }

  handleNext = () => {
    this.setState({
      currentTab: this.state.currentTab + 1
    })
  }

  clearChanges = () => {
    this.setState({
      edit: false,
      currentCellId: null,
      currentCellTitle: null,
    })
  }

  handlePrevious = () => {
    this.setState({
      currentTab: this.state.currentTab - 1
    })
  }

  handleCheckBox(val){
    let managedCheckboxArr = this.state.checkedBenefits
    if(this.state.checkedBenefits.length !== 0) {
      let findIndex = this.state.checkedBenefits.indexOf(val)
      if(findIndex !== -1) {
        this.state.checkedBenefits.splice(findIndex, 1)
        managedCheckboxArr = this.state.checkedBenefits
      }else {
        managedCheckboxArr.push(val)
      }
    } else {
      managedCheckboxArr.push(val)
    }
    this.setState({
      checkedBenefits: managedCheckboxArr
    })
  }

  handleCell = (id, title, text) => {
    this.setState({
      editedText: text,
      edit: true,
      currentCellId: id,
      currentCellTitle: title
    })
  }

  modifyTextInCell = (e) => {

    this.setState({
      editedText: e.target.value
    })
  }

  handleOnCheckboxChange = (key, e) => {
    let checked = e.target.checked,
        {item} = this.state;
    item[key] = checked;
    this.setState({item});
  }

  handleAutoCompleteChange = (key, value) => {
    let {item} = this.state;
    if (key == 'introductions') {
      key = "introduction";
    } else {
      key = "important_information";
    }
    item[key] = value;
    this.setState({item});
  }

  saveChanges = () => {
    let tempArr = this.state.data
    for (let key in this.state.data) {
      if(this.state.data[key].id === this.state.currentCellId) {
        tempArr[key][this.state.currentCellTitle] = this.state.editedText
        this.setState({
          data: tempArr,
          edit: false
        })
      }
    }
  }

  getPdf = (token) => {
    return AccountService.returnUrl() + "api" + baseLink + "pdf/" + token;
  }

  openModal(url) {
    this.setState(state => ({
      openPdf: true,
      pdfUrl: url
    }))
  }

  getPdfData = () => {
    if(this.state.edit) {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "the preview will not display the changes that haven't been saved"
      }))
    }
    let url = this.getUrl();
    url += "/pdf-token/";
    API.get(url)
      .then((res) => {
        if (res.data.success) {
          this.openModal(this.getPdf(res.data.code));
          return;
        }
        this.setState(state => ({
          modalOpen: true,
          modalMessage: res.data.error_message
        }))
      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
  }

  handleAction = (action) => {
    if (action == 'save') {
      this.handleSave();
      return;
    }
    if (action == 'previewPdf') {
      this.getPdfData();
    }
    if (action == 'duplicate') {
      this.props.history.push('/plans/add/' + this.props.match.params.id);
      return;
    }
    if (action == 'print') {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "Printing"
      }))
      return;
    }
    if (action == 'delete') {
      this.handleDelete();
      return;
    }
  }

  handleDelete = (e) => {
    // Load async data.
    let url = this.getUrl();
    API.delete(url)
      .then((res) => {
        this.props.history.push('/plans/');
      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
  }

  pdfHandleClose = () => {
    this.setState({
      openPdf: false
    })
  }

  handleSave = (e) => {
    //pull the url information from history object of cureent location
    let linkToNavigateTo = this.props.history.location.pathname.match(/\w*[-]?plans/)
    linkToNavigateTo = `/${linkToNavigateTo}/` || '//'

    if (this.state.isSaving) {
      return false;
    }

    let { item, benefits } = this.state,

        url = this.getUrl(true);

    if (!this.isValidItem(item)) {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "You must complete the form before continuing"
      }))
      return;
    }

    let dict = {
      'plan': item,
      'benefits': benefits
    }

    this.setState(state => ({
      isSaving: true
    }))

    //you can't do as it is below
    // this.state.isSaving = true;
    API.post(url, dict)
    .then((res) => {
      if (res.data.success) {
      this.props.history.push(linkToNavigateTo);
        } else {
          this.setState(state => ({
            modalOpen: true,
            modalMessage: "Cannot save with the current details",
            isSaving: false,
            edit: false
          }))
      }

    })
    .catch((error) => {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "Cannot save with the current details",
        isSaving: false
      }))
    });
  }

  isValidItem = (item) => {
    return true;
  }

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  // openModal = () => {
  //   this.setState(state => ({
  //     modalOpen: true,
  //     modalMessage: "the preview will not display the changes that haven't been saved"
  //   }))
  // }

  render() {
    const { classes } = this.props;
    const { options, currentTab, checkedBenefits, data, item,
           edit, currentCellId, currentCellTitle, editedText,
           planDesign, planType, rxPlanType, benefits, fieldObj,
           openPdf, pdfUrl, isExpanded, isVendor, isNotEditor} = this.state;
    let id = this.props.match.params.id,
        expandText = "Expand All";
    if (isExpanded) {
      expandText = "Collapse";
    }
    return (
      <div className={classes.base}>
      <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
      {openPdf &&
        <PdfModal pdfUrl={pdfUrl} openPdf={openPdf} close={() => this.pdfHandleClose()}/>
      }
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          <Grid item xs={isVendor ? 6 : 8}>
          {item &&
            <Typography component="h2" variant="h1" className="pageTitle">
              {item.Name}
            </Typography>
            }
          </Grid>
          <Grid item xs={isVendor ? 6 : 4} align="right">
            <IconButton onClick = {this.toggleExpand} className={classes.blueButton}>
              {expandText}
            </IconButton>
            {
              isVendor &&
              <IconButton component={Link} to={`/plan-modifications/add/${id}/`}
                          className={`${classes.blueButton} ${classes.planModBtn}`}>
                Request Modification
              </IconButton>
            }
            {window.location.href.indexOf("add") < 0 &&
              <IconButton component={Link} to={`/plans/${id}/history/`} className={classes.blueButton}>
                History
                <img src={IconHistory} className={classes.buttonIcon}/>
              </IconButton>
            }
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.paperScroll}>
                <Grid container className={classes.formcontainer}>
                  <EditToolbar
                    isNotEditor={isNotEditor}
                    isVendor={isVendor}
                    options={options}
                    isExpanded={isExpanded}
                    currentTab={currentTab}
                    updateCurrentTab={this.updateCurrentTab}
                    handleAction={(action)=> this.handleAction(action)}
                    isSaving={this.state.isSaving}
                  />
                </Grid>
                {options && options.tabs &&
                  <React.Fragment>
                  {options.tabs.map((tab, index) => {
                    let formFields = null;
                    if (currentTab == index || isExpanded) {
                      formFields = this.getFormFields(tab);
                    }
                      return (
                          <TabContainer>
                          {formFields &&
                            <React.Fragment>
                              <Divider className='searchLine searchLine__toolbar'/>
                              <Grid container style={{marginTop: 10}} className={classes.formcontainer}>
                                <Grid item xs={12} sm={6}>
                                    <Typography component="h3" className={classes.paperTitle}>{options.tabs[index]}</Typography>
                                </Grid>
                              </Grid>
                              <TabForm
                                isNotEditor={isNotEditor}
                                modifyTextInCell={this.modifyTextInCell}
                                handleOnCheckboxChange={this.handleOnCheckboxChange}
                                item={item}
                                benefits={benefits}
                                formFields={formFields}
                                fieldObj={fieldObj}
                                saveChanges={this.saveChanges}
                                handleAutoCompleteChange={this.handleAutoCompleteChange}
                                handleCell={this.handleCell}
                                currentCellId={this.state.currentCellId}
                                currentCellTitle={this.state.currentCellTitle}
                                edit={this.state.edit}
                                editedText={this.state.editedText}
                                clearChanges={this.clearChanges}
                                handleChange={this.handleChange}
                                openModal={this.openModal}
                              />
                            </React.Fragment>
                          }
                          </TabContainer>
                      )
                    }
                    )}
                  </React.Fragment>
                }
                <Divider className='searchLine__bottom'/>
                <Grid item xs={6} style={{marginTop: 80}} align="left">
                {!isExpanded && currentTab !== 0 &&
                    <IconButton
                      onClick = {this.handlePrevious}
                      className={`${classes.blueButton} ${classes.previousButton}`}
                      >
                      Previous
                    </IconButton>
                  }
                  {!isExpanded && currentTab < (options.tabs.length - 1) &&
                    <IconButton onClick = {this.handleNext} style={{ marginRight: 10 }} className={classes.blueButton}>
                      Next
                      <KeyboardArrowRightIcon className={classes.dropdownIcon}/>
                    </IconButton>
                  }
                </Grid>
              </Paper>
            </form>
          </Grid>
          </Grid>
      </div>
    );
  }
}

PlanItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PlanItem));

const AccountService = {
    returnData: function() {
        let token = localStorage.getItem("token");
        return token;
    },
    returnUrl: function() {
        return "https://emblemhealth.syncoms.com/"
        // return "http://127.0.0.1:8000/"
    }
};

export default AccountService;

import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '../../assets/icon-delete.png';
import AddIcon from '@material-ui/icons/Add';

import EditToolbar from '../../components/Fields/EditToolbar';
import AdminTable from '../../components/Fields/AdminTable';

const styles = theme => ({
  base: {},
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  pageTitle: {
    color:theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 30,
    paddingTop: theme.spacing.unit*2,
    paddingBottom: theme.spacing.unit*2
  },
  summaryLineButton: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    marginTop: 20
  },
  buttonIcon: {
    marginLeft: 15,
  },
  deleteIcon: {
    height: 24,
    width: 21
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  summarySelect: {
    width: '100%',
    height: 40,
  },
  checkboxInline: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    marginRight: theme.spacing.unit*3,
  },
  tableSelect: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing.unit*2,
    height: 40,
  },
  topAlign: {
    verticalAlign:'top'
  }
});

let counter = 0;

class SummaryItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      value: 'true',
      summary_item: {
        'name': '2020 POS-Std Cath Excl w/contraceptive-MCC'
      },
      options: {
        'actions': ['save', 'delete'],
      }
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { summary_item, options, pharmacyPlan, medicalPlan, effectiveDate, sbcPeriodPolicyMonth, sbcPeriodPolicyYear} = this.state;

    return (
      <div className={classes.base}>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className={classes.pageTitle}>
              Summaries
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.root}>
                <Grid container className={classes.formcontainer}>
                  <EditToolbar options={options}/>
                </Grid>
                <Grid container className={classes.formcontainer}>
                  <Grid item xs={12} sm={6}>
                    <Typography component="h3" className={classes.paperTitle}>Add Summary</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={6}>
                    <Grid container spacing={16} className={classes.formRow}>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">Group Name</Typography>
                        <TextField
                          required
                          fullWidth
                          id="group_name"
                          name="group_name"
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">Effective Date</Typography>
                        <Select
                          name="effectiveDate"
                          value={effectiveDate}
                          onChange={(e) => this.handleChange(e)}
                          className={classes.summarySelect}
                        >
                          <MenuItem value={'January'}>January</MenuItem>                  
                          <MenuItem value={'February'}>February</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container spacing={16} className={classes.formRow}>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">SBC Period Policy Month</Typography>
                        <Select
                          name="sbcPeriodPolicyMonth"
                          value={sbcPeriodPolicyMonth}
                          onChange={(e) => this.handleChange(e)}
                          className={classes.summarySelect}
                        >
                          <MenuItem value={'January'}>January</MenuItem>
                          <MenuItem value={'February'}>February</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography className="formLabel">SBC Period Policy Year</Typography>
                        <Select
                          name="sbcPeriodPolicyYear"
                          value={sbcPeriodPolicyYear}
                          onChange={(e) => this.handleChange(e)}
                          className={classes.summarySelect}
                        >
                          <MenuItem value={2019}>2019</MenuItem>                    
                          <MenuItem value={2018}>2018</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>           
                <Divider/>
                <Grid container>
                   <Grid item xs={12} sm={6}>
                    <Typography component="h3" className={classes.paperTitle}>Summary Lines</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Medical Plan
                          </TableCell>                          
                          <TableCell>
                            Pharmacy Plan
                          </TableCell>                          
                          <TableCell>
                            Summary Types
                          </TableCell>                         
                          <TableCell>
                            Generate SBC
                          </TableCell>          
                          <TableCell>
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>  
                      <TableBody>
                        <TableRow className={classes.topAlign}>
                          <TableCell>
                          <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>HMO</div>  
                            </div>                       
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>POS</div>   
                            </div>                  
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>2019</div> 
                            </div>
                            <Select
                              name="medicalPlan"
                              value={medicalPlan}
                              onChange={(e) => this.handleChange(e)}
                              className={classes.tableSelect}  
                            >
                              <MenuItem value={2019}>2019</MenuItem>                    
                              <MenuItem value={2018}>2018</MenuItem>
                            </Select>
                          </TableCell>                         
                          <TableCell>
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>HMO RX</div>  
                            </div>                       
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>POS RX</div>   
                            </div>                  
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>2019</div> 
                            </div>
                            <Select
                              name="pharmacyPlan"
                              value={pharmacyPlan}
                              onChange={(e) => this.handleChange(e)}
                              className={classes.tableSelect}  
                            >
                              <MenuItem value={2019}>2019</MenuItem>                    
                              <MenuItem value={2018}>2018</MenuItem>
                            </Select>
                          </TableCell>                         
                          <TableCell>
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>A</div>  
                            </div>                       
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>B</div>   
                            </div>                  
                            <div className={classes.checkboxInline}>
                              <Checkbox/> <div className={classes.checkboxLabel}>C</div> 
                            </div>
                          </TableCell>                         
                          <TableCell>
                            <Checkbox/>
                          </TableCell>                          
                          <TableCell>
                            <IconButton>
                              <img src={DeleteIcon} className={classes.deleteIcon}/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} align="left">
                  <IconButton  className={classes.summaryLineButton}>
                    Add another Sumary line <AddIcon className={classes.buttonIcon}/>
                  </IconButton>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SummaryItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SummaryItem);
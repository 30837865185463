import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, Link, Paper, Toolbar, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import SearchBar from '../Fields/SearchBar';
import EditSensorModal from './EditSensorModal';

let counter = 0;
function createData(name, available, last_name, mac_address, expires, last_activity) {

  counter += 1;
  return { id: counter, name, available, last_name, mac_address, expires, last_activity };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'mac_address', numeric: false, disablePadding: false, label: 'MAC Address' },
  { id: 'available', numeric: false, disablePadding: false, label: 'Available' },
  { id: 'expires', numeric: false, disablePadding: false, label: 'Expires' },
  { id: 'last_activity', numeric: false, disablePadding: false, label: 'Last Activity' },
];

class SensorFormHead extends React.Component {

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
            {rows.map(
              row => (
                <TableCell
                  key={row.id}
                  align={row.numeric ? 'right' : 'left'}
                  padding={row.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === row.id ? order : false}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ),
              this,
            )}
          </TableRow>
        </TableHead>
    );
  }
}

SensorFormHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    width: '100%',
    marginTop: 30,
  },
  tableWrapper: {
  },
});

class SensorForm extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'name',
    selected: [],
    data: [
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', false, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
      createData('Sensor Name', true, 'nickname', '00:0a:95:9d:68:16', '00:00 - 14/08/2019', '00:00 - 14/08/2019'),
    ],
    page: 0,
    rowsPerPage: 10,
  };

  constructor(props) {
    super(props);
    this.modal = React.createRef();
  }

  handleChange(id) {
    if (this.state.selected.indexOf(id) < 0) {
      this.modal.current.handleClickOpen();
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div className={classes.root}>
        <Grid container alignItems={'center'} spacing={24}>
          <Grid item xs={12} sm={6}>
            <SearchBar placeholder="Search MAC address" />
          </Grid>
        </Grid>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <SensorFormHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} onChange={e => {this.handleChange(n.id);}} />
                      </TableCell>
                      <TableCell>{n.mac_address}</TableCell>
                      <TableCell>{n.available ?
                                  <CheckCircleOutlineIcon  style={{fontSize: 16,fill:'#8bc34a'}} /> :
                                  <HighlightOffIcon style={{fontSize: 16,fill:'#f44336'}}/>
                                }</TableCell>
                      <TableCell>{n.expires}</TableCell>
                      <TableCell>{n.last_activity}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <EditSensorModal innerRef={this.modal} />
      </div>
    );
  }
}

SensorForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SensorForm);

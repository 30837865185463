import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconHistory from '../../assets/icon-history.png'
import CheckCircleOutlineIcon from '../../assets/icon-check-circle.png';
import CheckIcon from '../../assets/icon-check.png' 
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput, Divider } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';


import EditToolbar from '../../components/Fields/EditToolbar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CalendarIcon from '../../assets/icon-calendar.png';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';
import CloseIcon from '@material-ui/icons/Close';

import API from "../../utils/API";

function TabContainer(props) {
  return (
      props.children
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  base: {},
  root: {
    // padding: theme.spacing.unit * 2
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  closeIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  subTitleText: {
    color: '#aebcdc',
    fontFamily: 'Calibri',
    fontSize: 14,
    fontWeight: 400
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1
  },
  labelForDatePicker: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Calibri',
    fontSize: 18,
    fontWeight: 40,
    color: theme.palette.primary.main
  },
  labelInRequestTab: {
    fontSize: 18,
    fontWeight: 40,
    color: theme.palette.primary.main
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff'
    }
  },
  pickerContainer: {
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  checkIcon: {
    width: 18,
    height: 'auto'
  },
  titleOfRow: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 20
  },
  titleOfCheckBox: {
    fontWeight: 400,
    fontSize: 18
  },
  checkBoxContainer: {
    borderBottom: '1px solid #e7edee'
  },
  paperContainer: {
    position: 'relative'
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formLabelForRequest: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  formLabel: {
    color: theme.palette.primary.light,
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '17px'
  },
  formRow: {
    marginBottom: theme.spacing.unit*3,
    fontSize: 17,
    color: theme.palette.primary.main
  },
  summarySelect: {
    width: '100%',
    height: 40,
  }
});

class ChangePasswordModal extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    let item = {},
        options = {
          'actions': ['save']
        };
    this.state = {
      item: item,
      currentTab: 0,
      open: this.props.changePasswordClicked,
      value: 'true',
      options: options,
      groups: ["Editor","Underwriter","Vendor"]
    };
    if (!item) {
      setTimeout(() => this.getData());
    }
  }

  confirmValidVerificationToken = (verificationToken) => {
    API.get('/verification-token/' + verificationToken)
    .then((res) => {
      if (res.data.success) {
        this.setState({canContinue: true});
        return;
      }
      this.setState({
          'message': 'Cannot confirm valid verification token',
          'open': true
        });
    })
    .catch((error) => {
      this.setState({
          'message': 'Cannot continue with the current details',
          'open': true
        });
    });
  }

  handleClose = () => {
    this.props.close()
    this.setState({'open': false});
  }

  handleResetPassword = (event) => {
    event.preventDefault();
    let password = this.state.password,
        confirmPassword = this.state.confirmPassword;

    if (!(password && confirmPassword)) {
      this.setState({
          'message': 'You must complete the form correctly',
          'open': true
        });
      return false;
    }
    if (password.length < 8) {
      this.setState({
          'message': 'Password strength is not enough',
          'open': true
        });
      return false;
    }
    if (password != confirmPassword) {
      this.setState({
          'message': 'Passwords do not match',
          'open': true
        });
      return false;
    }
    this.resetPassword(password);
    return false;
  }

  resetPassword = (password) => {
    API.post('/users/' + this.props.userId + '/password/', {
      password: password,
    })
    .then((res) => {
      if (res.data.success) {
        this.handleClose();
        return;
      }
        this.setState({
            'message': 'There was an undefined issue in handling this.',
            'open': true
          });

    })
    .catch((error) => {
      this.setState({
          'message': 'Cannot continue with the current details',
          'open': true
        });
    });
    
  }

  handleUpdate = (name, value) => {
    this.setState({[name]: value});
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleResetPassword(e);
    }
  }


  render() {
    const { classes } = this.props;
    const {open} = this.state;

    return (
            <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            maxWidth="xs"
            className={classes.dialog}
            >
            <DialogTitle id="form-dialog-title" disableTypography={true} className={classes.modaltitle}>
            <Grid item xs={12} sm={12} style={{display: 'flex', justifyContent: 'space-between'}} className={classes.titleOfRow}>
            <Grid>
              Change your password
            </Grid>
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleDrawerToggle}>
              <CloseIcon onClick ={this.handleClose} className={classes.closeIcon}/>
           </IconButton>
            </Grid>
            </DialogTitle>
            <DialogContent
            style={{
                overflow: 'visible',
                paddingBottom: 0
            }} >
        <form className={classes.form} noValidate>
                <Grid container className={classes.formcontainer}>
                <Grid item xs={12} sm={12} className={classes.formLabel}>Type new password</Grid>
                <Grid item xs={12} sm={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  name="password"
                  type="password"
                  onKeyDown={this._handleKeyDown}
                  onChange={e => this.handleUpdate('password', e.target.value)}
                  />
                </Grid> 
                <Grid item xs={12} sm={12} style={{marginTop: 20}} className={classes.formLabel}>Confirm new assword</Grid>
                <Grid item xs={12} sm={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  onKeyDown={this._handleKeyDown}
                  onChange={e => this.handleUpdate('confirmPassword', e.target.value)}
                  />
                </Grid>
                </Grid>  
                <Divider className='searchLine__bottom' style={{marginBottom: 15}}/>
                <Grid container alignItems="center">
                <Grid item xs align="right" style={{marginTop: 40}}>
                    <IconButton 
                      onClick = {this.handleClose} 
                      className={`${classes.blueButton} ${classes.previousButton}`}
                      >
                      Cancel 
                    </IconButton>
                    <IconButton onClick = {this.handleResetPassword} className={classes.blueButton}>
                          Update password
                    </IconButton>
                </Grid>
                </Grid>
        </form>
            </DialogContent>
        </Dialog>
            );
  }
}

ChangePasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChangePasswordModal);


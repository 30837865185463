import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import EditToolbar from '../../components/Fields/EditToolbar';
import Divider from '@material-ui/core/Divider';
import IconHistory from '../../assets/icon-history.png'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const styles = theme => ({
  base: {},
  root: {
    // padding: theme.spacing.unit * 2
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  paper: {
    position: 'relative'
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,
    paddingBottom: theme.spacing.unit*1,
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit*0.5,
    borderRadius: 24,
    fontSize: 18,
    display: 'flex',
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#27cfda',
      color: '#fff'
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  }
});

class GroupItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      value: 'true',
      important_information: {
        'name': '2020 POS-Std Cath Excl w/contraceptive-MCC'
      },
      options: {
        'actions': ['save', 'delete'],
      }
    };
  }

  handleChange = event => {
      this.setState({ value: event.target.value });
    };

  render() {
    const { classes } = this.props;
    const { important_information, options } = this.state;
    return (
      <div className={classes.base}>
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className="pageTitle">
              Groups
              <KeyboardArrowUpIcon className={classes.dropdownIcon}/>
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <IconButton component={Link} to="/groups/history" className={classes.blueButton}>
            History
            <img src={IconHistory} className={classes.buttonIcon}/>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.paper}>
                <Grid container className={classes.formcontainer}>
                  <EditToolbar options={options} />
                  <Divider className='searchLine searchLine__toolbar'/>
                </Grid>
                <Grid container className={classes.formcontainer}>
                  <Grid item xs={12} sm={6}>
                    <Typography component="h3" className={classes.paperTitle}>Change Group</Typography>
                  </Grid>
                </Grid>
                <Grid container className={classes.formRow}>
                  <Grid item xs={12} sm={4}>
                    <Grid item xs={12} sm={12} className="formLabel">Name</Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        fullWidth
                        id="name_of_sensor"
                        name="name"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

GroupItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupItem);

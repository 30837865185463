import React from 'react';

// API
import API from "../../utils/API";

// COMPONENTS
import ChipInput from 'material-ui-chip-input'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import HorizontalLinearStepper from '../CreateEvent/HorizontalLinearStepper';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppModal from "../../components/Modals/AppModal"

const styles = theme => ({
  dialog: {
    padding: 30
  },
  modaltitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: "Calibri, sans-serif",
    fontSize: 36,
  },
});


class ErrorModal extends React.Component {

  state = {
    open: false,
    clients: null,
    name: '',
    client: '',
    industry: '',
    participants: [],
    modalOpen: false,
    modalMessage: '',
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  render() {
    const { classes } = this.props;
    const { participants } = this.state;
    
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className={classes.dialog}
        >
          <DialogTitle id="form-dialog-title" disableTypography={true} className={classes.modaltitle}>Error</DialogTitle>
          <DialogContent
            style={{
              overflow: 'visible',
              paddingBottom: 0
            }} >
            {this.props.message}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Okay
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  async componentDidMount() {
    
  }
}

ErrorModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorModal);

import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import IconButton from '@material-ui/core/IconButton';

import EditCalendarViewModal from './EditCalendarViewModal';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

const styles = theme => ({
  returnButton: {
      border: '2px solid #1b4059',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      minWidth: 'auto',
      borderRadius: 3,
      textTransform: 'initial',
      fontSize: 14,
      padding: '0 10px',
      fontFamily: "Calibri, sans-serif"
    },
});

class AdvancedCalendarViewForm extends React.Component {
    constructor(props) {
      super(props);
      this.handleDayClick = this.handleDayClick.bind(this);
      this.modal = React.createRef();

      const modifiers = {
        customDates: [new Date(2019, 7, 23), new Date(2019, 7, 24)],
        startDate: new Date(2019, 7, 15),
        endDate: new Date(2019, 7, 25)
      }
      let calendarDetails = {
        'initialMonth': new Date(2019, 7),
        'selectedDays': [
            new Date(2019, 7, 15),
            new Date(2019, 7, 17),
            {
                after: new Date(2019, 7, 20),
                before: new Date(2019, 7, 26),
            },
        ],
        'fromMonth': new Date(2019, 7),
        'toMonth': new Date(2019, 8),
        'modifiers': modifiers
      };
      this.state = {
        value: 'true',
        calendar: calendarDetails
      };
    }

    handleReturn(e) {
      this.props.close();
    };

    handleDayClick(day) {
      // const range = DateUtils.addDayToRange(day, this.state);
      // this.setState(range);
      this.modal.current.handleClickOpen();
    }


    render() {
        const { classes } = this.props;
        const { calendar } = this.state;
        return (
            <div>
            <Grid container>
              <Grid item xs={12}>
              <IconButton className={classes.returnButton} onClick={e => this.handleReturn(e) }>
                <SkipPreviousIcon /> Return
              </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Helmet>
                <style>{`
                  .DayPicker-Month {
                    border: 2px solid #e2eef6; 
                    padding: 1rem;
                    display: block;
                  }
                  .DayPicker-Day {
                    border-radius: 0;
                  }
.DayPicker-Body .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--startDate, 
.DayPicker-Body .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside).DayPicker-Day--endDate {
background-color:#1b4059;
color: #fff;
}
                  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                    background-color:#e2eef6;
                    color: #000;
                  }

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
background-color:#1b4059;
color:#fff;
}

                  .DayPicker-Body .DayPicker-Week .DayPicker-Day.DayPicker-Day--customDates {
                    background-color: #ffc400;
                    color: black;
                  }
                  .DayPicker-Body .DayPicker-Day--startDate {
                      border-top-left-radius: 50% !important;
                      border-bottom-left-radius: 50% !important;
                      border-top-right-radius: 0% !important;
                      border-bottom-right-radius: 0% !important;
                    }
                    .DayPicker-Body .DayPicker-Day--endDate {
                      border-top-left-radius: 0% !important;
                      border-bottom-left-radius: 0% !important;
                      border-top-right-radius: 50% !important;
                      border-bottom-right-radius: 50% !important;
                    };
                  `}</style>
              </Helmet>
                <DayPicker
                    numberOfMonths={2}
                    initialMonth={calendar.initialMonth}
                    selectedDays={calendar.selectedDays}
                    fromMonth={calendar.fromMonth}
                    toMonth={calendar.toMonth}
                    modifiers={calendar.modifiers}
                    onDayClick={this.handleDayClick}
                    />
                    <EditCalendarViewModal innerRef={this.modal} />
              </Grid>
            </Grid>
            </div>
            )
    }
}
AdvancedCalendarViewForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdvancedCalendarViewForm);
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  base: {},
  root: {
    padding: theme.spacing.unit * 2
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  pagetitle: {
    color:theme.palette.primary.main,
    paddingLeft: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 3
  },
});

class SensorItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      value: 'true'
    };
  }

  handleChange = event => {
      this.setState({ value: event.target.value });
    };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.base}>
        <Grid container component="main" className={classes.root}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className={classes.pagetitle}>
              Sensor
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.root}>
                <Grid container className={classes.formcontainer}>
                  <Grid item xs={12} sm={6}>
                    <Grid container className={classes.row}>
                      <Grid item xs={12} sm={4} className={classes.label}>Name</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name_of_sensor"
                        name="name"
                      />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={4} className={classes.label}>Short Name</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="short_name"
                            name="short_name"
                            autoFocus
                          />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={4} className={classes.label}>Status</Grid>
                      <Grid item xs={12} sm={8}>
                        <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup
                            aria-label="Status"
                            name="status"
                            className={classes.group}
                            value={this.state.value}
                            onChange={this.handleChange}
                          >
                            <Grid container>
                              <Grid item xs={6}>                            
                                <FormControlLabel value="true" control={<Radio />} label="Active" />
                              </Grid>    
                              <Grid item xs={6}>
                                <FormControlLabel value="false" control={<Radio />} label="Inactive" />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={4} className={classes.label}>MAC Address</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                            margin="normal"
                            disabled
                            fullWidth
                            id="mac_address"
                            name="mac_address"
                          />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={4} className={classes.label}>Expires</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                            margin="normal"
                            disabled
                            fullWidth
                            id="short_name"
                            name="short_name"
                            autoFocus
                          />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={4} className={classes.label}>Last Activity</Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                            margin="normal"
                            disabled
                            fullWidth
                            id="short_name"
                            label="Last Activity"
                            name="short_name"
                          />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleNext}
                          className={classes.button}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>  
              </Paper>
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SensorItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SensorItem);
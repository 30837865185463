/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { MenuItem, Menu as MenuList, Popover } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { components as selectComponents } from 'react-select'

import API from "../../utils/API";

const locationSuggestions = [
  {label: 'Albany'},
  {label: 'Andover'},
  {label: 'Arcadia'},
  {label: 'Atlanta'},
  {label: 'Aspen'},
  {label: 'Austin'},
  {label: 'Baltimore'},
  {label: 'Boston'},
  {label: 'Buffalo'},
  {label: 'Charlotte'},
  {label: 'Chicago'},
  {label: 'Cincinnati'},
  {label: 'Cleveland'},
  {label: 'Columbus'},
  {label: 'Dallas'},
  {label: 'Denver'},
  {label: 'Detroit'},
  {label: 'Fort Lauderdale'},
  {label: 'Greenville'},
  {label: 'Hartford'},
  {label: 'Houston'},
  {label: 'Indianapolis'},
  {label: 'Jacksonville'},
  {label: 'Kansas City'},
  {label: 'La Jolla'},
  {label: 'Las Vegas'},
  {label: 'Long Beach'},
  {label: 'Los Angeles'},
  {label: 'Louisville'},
  {label: 'Memphis'},
  {label: 'Miami'},
  {label: 'Milwaukee'},
  {label: 'Minneapolis'},
  {label: 'Nashville'},
  {label: 'New Orleans'},
  {label: 'New Rochelle'},
  {label: 'New York'},
  {label: 'Norfolk'},
  {label: 'Oklahoma City'},
  {label: 'Orlando'},
  {label: 'Philadelphia'},
  {label: 'Phoenix'},
  {label: 'Pittsburgh'},
  {label: 'Portland'},
  {label: 'Providence'},
  {label: 'Raleigh'},
  {label: 'Rochester'},
  {label: 'Sacramento'},
  {label: 'Salt Lake City'},
  {label: 'San Antonio'},
  {label: 'San Diego'},
  {label: 'San Francisco'},
  {label: 'San Marcos'},
  {label: 'Seattle'},
  {label: 'St. Louis'},
  {label: 'Stamford'},
  {label: 'Tampa'},
  {label: 'The Villages'},
  {label: 'Washington'},
  {label: 'West Palm Beach'},
  {label: 'San Jose'},
  {label: 'Texas'},
  {label: 'Eugene'},
  {label: 'New Jersey'},
  {label: 'Santa Rosa'},
  {label: 'Concord'},
  {label: 'Pebble Beach'},
  {label: 'Silver Spring'},
  {label: 'Newport News'},
  {label: 'Richmond'},
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const phoneNumberSuggestions = [
    {"label": "+7 840"},
    {"label": "+93"},
    {"label": "+355"},
    {"label": "+213"},
    {"label": "+1 684"},
    {"label": "+376"},
    {"label": "+244"},
    {"label": "+1 264"},
    {"label": "+1 268"},
    {"label": "+54"},
    {"label": "+374"},
    {"label": "+297"},
    {"label": "+247"},
    {"label": "+61"},
    {"label": "+672"},
    {"label": "+43"},
    {"label": "+994"},
    {"label": "+1 242"},
    {"label": "+973"},
    {"label": "+880"},
    {"label": "+1 246"},
    {"label": "+1 268"},
    {"label": "+375"},
    {"label": "+32"},
    {"label": "+501"},
    {"label": "+229"},
    {"label": "+1 441"},
    {"label": "+975"},
    {"label": "+591"},
    {"label": "+387"},
    {"label": "+267"},
    {"label": "+55"},
    {"label": "+246"},
    {"label": "+1 284"},
    {"label": "+673"},
    {"label": "+359"},
    {"label": "+226"},
    {"label": "+257"},
    {"label": "+855"},
    {"label": "+237"},
    {"label": "+1"},
    {"label": "+238"},
    {"label": "+ 345"},
    {"label": "+236"},
    {"label": "+235"},
    {"label": "+56"},
    {"label": "+86"},
    {"label": "+61"},
    {"label": "+61"},
    {"label": "+57"},
    {"label": "+269"},
    {"label": "+242"},
    {"label": "+243"},
    {"label": "+682"},
    {"label": "+506"},
    {"label": "+385"},
    {"label": "+53"},
    {"label": "+599"},
    {"label": "+537"},
    {"label": "+420"},
    {"label": "+45"},
    {"label": "+246"},
    {"label": "+253"},
    {"label": "+1 767"},
    {"label": "+1 809"},
    {"label": "+670"},
    {"label": "+56"},
    {"label": "+593"},
    {"label": "+20"},
    {"label": "+503"},
    {"label": "+240"},
    {"label": "+291"},
    {"label": "+372"},
    {"label": "+251"},
    {"label": "+500"},
    {"label": "+298"},
    {"label": "+679"},
    {"label": "+358"},
    {"label": "+33"},
    {"label": "+596"},
    {"label": "+594"},
    {"label": "+689"},
    {"label": "+241"},
    {"label": "+220"},
    {"label": "+995"},
    {"label": "+49"},
    {"label": "+233"},
    {"label": "+350"},
    {"label": "+30"},
    {"label": "+299"},
    {"label": "+1 473"},
    {"label": "+590"},
    {"label": "+1 671"},
    {"label": "+502"},
    {"label": "+224"},
    {"label": "+245"},
    {"label": "+595"},
    {"label": "+509"},
    {"label": "+504"},
    {"label": "+852"},
    {"label": "+36"},
    {"label": "+354"},
    {"label": "+91"},
    {"label": "+62"},
    {"label": "+98"},
    {"label": "+964"},
    {"label": "+353"},
    {"label": "+972"},
    {"label": "+39"},
    {"label": "+225"},
    {"label": "+1 876"},
    {"label": "+81"},
    {"label": "+962"},
    {"label": "+7 7"},
    {"label": "+254"},
    {"label": "+686"},
    {"label": "+965"},
    {"label": "+996"},
    {"label": "+856"},
    {"label": "+371"},
    {"label": "+961"},
    {"label": "+266"},
    {"label": "+231"},
    {"label": "+218"},
    {"label": "+423"},
    {"label": "+370"},
    {"label": "+352"},
    {"label": "+853"},
    {"label": "+389"},
    {"label": "+261"},
    {"label": "+265"},
    {"label": "+60"},
    {"label": "+960"},
    {"label": "+223"},
    {"label": "+356"},
    {"label": "+692"},
    {"label": "+596"},
    {"label": "+222"},
    {"label": "+230"},
    {"label": "+262"},
    {"label": "+52"},
    {"label": "+691"},
    {"label": "+1 808"},
    {"label": "+373"},
    {"label": "+377"},
    {"label": "+976"},
    {"label": "+382"},
    {"label": "+1664"},
    {"label": "+212"},
    {"label": "+95"},
    {"label": "+264"},
    {"label": "+674"},
    {"label": "+977"},
    {"label": "+31"},
    {"label": "+599"},
    {"label": "+1 869"},
    {"label": "+687"},
    {"label": "+64"},
    {"label": "+505"},
    {"label": "+227"},
    {"label": "+234"},
    {"label": "+683"},
    {"label": "+672"},
    {"label": "+850"},
    {"label": "+1 670"},
    {"label": "+47"},
    {"label": "+968"},
    {"label": "+92"},
    {"label": "+680"},
    {"label": "+970"},
    {"label": "+507"},
    {"label": "+675"},
    {"label": "+595"},
    {"label": "+51"},
    {"label": "+63"},
    {"label": "+48"},
    {"label": "+351"},
    {"label": "+1 787"},
    {"label": "+974"},
    {"label": "+262"},
    {"label": "+40"},
    {"label": "+7"},
    {"label": "+250"},
    {"label": "+685"},
    {"label": "+378"},
    {"label": "+966"},
    {"label": "+221"},
    {"label": "+381"},
    {"label": "+248"},
    {"label": "+232"},
    {"label": "+65"},
    {"label": "+421"},
    {"label": "+386"},
    {"label": "+677"},
    {"label": "+27"},
    {"label": "+500"},
    {"label": "+82"},
    {"label": "+34"},
    {"label": "+94"},
    {"label": "+249"},
    {"label": "+597"},
    {"label": "+268"},
    {"label": "+46"},
    {"label": "+41"},
    {"label": "+963"},
    {"label": "+886"},
    {"label": "+992"},
    {"label": "+255"},
    {"label": "+66"},
    {"label": "+670"},
    {"label": "+228"},
    {"label": "+690"},
    {"label": "+676"},
    {"label": "+1 868"},
    {"label": "+216"},
    {"label": "+90"},
    {"label": "+993"},
    {"label": "+1 649"},
    {"label": "+688"},
    {"label": "+1 340"},
    {"label": "+256"},
    {"label": "+380"},
    {"label": "+971"},
    {"label": "+44"},
    {"label": "+1"},
    {"label": "+598"},
    {"label": "+998"},
    {"label": "+678"},
    {"label": "+58"},
    {"label": "+84"},
    {"label": "+1 808"},
    {"label": "+681"},
    {"label": "+967"},
    {"label": "+260"},
    {"label": "+255"},
    {"label": "+263"}
  ].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const eventSuggestions = [
  {label: 'Normal'},
  {label: 'Retail'},
  {label: 'Brand activations indoors'},
  {label: 'Brand activations outdoors'},
  {label: 'Exhibitions - activations'},
  {label: 'Fanzone Festival campaigns'},
  {label: 'Right Holders'},
  {label: 'Dealerships'},
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const timeSuggestions = [
  {label: '12:00 AM'},
  {label: '12:30 AM'},
  {label: '01:00 AM'},
  {label: '01:30 AM'},
  {label: '02:00 AM'},
  {label: '02:30 AM'},
  {label: '03:00 AM'},
  {label: '03:30 AM'},
  {label: '04:00 AM'},
  {label: '04:30 AM'},
  {label: '05:00 AM'},
  {label: '05:30 AM'},
  {label: '06:00 AM'},
  {label: '06:30 AM'},
  {label: '07:00 AM'},
  {label: '07:30 AM'},
  {label: '08:00 AM'},
  {label: '08:30 AM'},
  {label: '09:00 AM'},
  {label: '09:30 AM'},
  {label: '10:00 AM'},
  {label: '10:30 AM'},
  {label: '11:00 AM'},
  {label: '11:30 AM'},
  {label: '12:00 PM'},
  {label: '12:30 PM'},
  {label: '01:00 PM'},
  {label: '01:30 PM'},
  {label: '02:00 PM'},
  {label: '02:30 PM'},
  {label: '03:00 PM'},
  {label: '03:30 PM'},
  {label: '04:00 PM'},
  {label: '04:30 PM'},
  {label: '05:00 PM'},
  {label: '05:30 PM'},
  {label: '06:00 PM'},
  {label: '06:30 PM'},
  {label: '07:00 PM'},
  {label: '07:30 PM'},
  {label: '08:00 PM'},
  {label: '08:30 PM'},
  {label: '09:00 PM'},
  {label: '09:30 PM'},
  {label: '10:00 PM'},
  {label: '10:30 PM'},
  {label: '11:00 PM'},
  {label: '11:30 PM'},
].map(suggestion => ({
  value: suggestion.label,
  label: suggestion.label,
}));

const styles = theme => ({
  root: {
    flexGrow: 1,
    // height: 250,
  },
  input: {
    display: 'flex',
    height: 40,
    padding: '0 10px',
    fontSize: 14,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  menuStyles: {
    fontSize: 12
  },
  noOptionsMessage: {
    padding: theme.spacing.unit * 2,
  },
  singleValue: {
    fontSize: 12,
  },
  placeholder: {
    
  },
  paper: {
    position: 'fixed',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 'auto',
    right: 'auto',
    fontFamily: "Calibri, sans-serif",
    fontSize:14,
    width:370,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  chip: {
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2, 
    fontFamily: "Calibri, sans-serif",
    fontSize:14,
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08,),
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize: 14,
        fontFamily: "Calibri, sans-serif",
        color: props.isSelected ? '#fff' : '#1b4059',
        padding:5,
        width: 1000
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <div
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
}

function SingleValue(props) {
  return (
    <div className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </div>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const DropdownIndicator = (innerProps) => {
  return (
    <selectComponents.DropdownIndicator {...innerProps}>
      <ArrowDropDownIcon />
    </selectComponents.DropdownIndicator>
  );
}

// const CustomMenuList = ({innerProps, children}) => {
//   return (
//     <MenuList {...innerProps}>{children}</MenuList>
//   )
// }

// CustomMenu = (props) => {
//   return (
//       <Popover {...props.innerProps} 
//         anchorEl={this.selectRef} 
//         open={props.selectProps.menuIsOpen} 
//       >
//         {props.children}
//       </Popover>
//   )
// }

const CustomOption = ({innerProps, children}) => {
  return (
    <MenuItem {...innerProps}>{children}</MenuItem>
  )
}

const selectStyles = {
  input: base => ({
    ...base,
    maxHeight: 200,
    '& input': {
      font: 'inherit',
    }
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none'
  }),
  control: (base) => ({
    ...base,
    border: '1px solid #e7edee',
    backgroundColor: '#f2f7f8',
    borderRadius: '3px',
    height: '42px',
    '&:hover': {
      boxShadow: 'none',
      outline: 'none'
    },
  }),
  menu: (base) => ({
    ...base,
    width: 'auto',
    minWidth: '100%'
  }),
  placeholder: (base) => ({
    ...base,
    display: 'none'
  }),
  singleValue: (base) => ({
    ...base,
    color: '#27498f',
    fontSize: '18px',
    fontFamily: 'Calibri sans-serif'
  }),
  dropdownIndicator: base => ({
    color: 'rgba(0, 0, 0, 0.54)'
  })
};
  

class AutoComplete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        single: props.value,
        multi: null,
        suggestions: [],
        placeholder: 'Select an event type',
        selectRef: null
    };
    if (props.list === 'users') {
      this.state.suggestions = props.data;
      this.state.placeholder = 'User';
      let value = props.value;
      for (let suggestion of props.data) {
        if (suggestion.value == value) {
          this.state.single = suggestion;
        }
      }
    }
  };

  handleChange = (value) => {
    this.setState({single: value});
    if (this.props.full) {
      this.props.update(value);
      return;
    }
    if (value) {
      this.props.update(value.value);
    } else {
      this.props.update(value);
    }
  }

  getUrl = (inputValue) => {
    let url = this.props.type + "/autocomplete";
    let queryList = [];
    if (inputValue) {
      queryList.push("n=" + inputValue);
    }
    if (this.props.DocumentTypes) {
      queryList.push("dt=" + (this.props.DocumentTypes.join(",")));
    }
    if (this.props.PlanYears) {
      queryList.push("py=" + (this.props.PlanYears.join(",")));
    }
    if (queryList.length) {
      url += "?" + queryList.join("&");
    }
    return url;
  }

  loadOptions = (inputValue, callback) => {
    let url = this.getUrl(inputValue);
    if (this.state.suggestions.length) {
      return callback(this.state.suggestions.filter((obj) => { return obj.label.indexOf(inputValue) >= 0 }));
    }
    API.get(url)
    .then((res) => {
      if (this.state.single && typeof(this.state.single) != 'object') {
        for (let suggestion of res.data.items) {
          if (suggestion.value == this.state.single) {
            this.state.single = suggestion;
            this.setState({single: suggestion}, callback(res.data.items));
            return;
          }
        }
      }
      callback(res.data.items);
    });
  };

  render() {
    const { classes, theme, isNotEditor, underwriterCannotEdit } = this.props;
    const { placeholder, suggestions } = this.state;

    return (
      <div className={classes.root}>
          <AsyncSelect
              ref={el => this.selectRef = el}
              // cacheOptions
              loadOptions={this.loadOptions}
              defaultOptions
              onChange={this.handleChange}
              className={classes.summarySelect}
              classes={classes}
              styles={selectStyles}
              value={this.state.single}
              components={{DropdownIndicator, Option: CustomOption}}
              isDisabled={isNotEditor || underwriterCannotEdit}
            />
      </div>
    );
  }
}

AutoComplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(AutoComplete);
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import userExample from '../assets/user_example.png';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import Fade from '@material-ui/core/Fade';
import AppModal from "../components/Modals/AppModal"


// API
import API from "../utils/API";
import ProfileService from "../utils/ProfileService";

// ASSETS
import logo from '../assets/emblemhealth-logo.png';

// COMPONENTS
import { AppBar, Toolbar, Avatar, Grid, Typography, Button, IconButton, ListItemIcon, ListItemText, ClickAwayListener, Grow, Paper, Popper, Link, Menu, MenuItem, MenuList, Icon, Divider } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import UserIcon from '@material-ui/icons/SupervisorAccount';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';

// IMAGES
import Settings from '../assets/icon-settings.png';
import UserGroup from '../assets/icon-user_groups.png';
import ImportantInformation from '../assets/icon-important_information.png';
import Summaries from '../assets/icon-summary_files.png';
import Authentication from '../assets/icon-authetication.png';
import UserManagement from '../assets/icon-user_management.png';

const styles = theme => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    padding: '0 30px',
    zIndex: theme.zIndex.drawer + 1,
    height: 84,
    justifyContent: 'center'
  },
  toolbar: {
    minHeight: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
  popperContainer: {
    marginTop: 23,
    zIndex: 10000,
    '&:before': {
        content: "' '",
        position: 'absolute',
        top: -16,
        right: 50,
        borderBottom: '8px solid lightgrey',
        borderRight: '8px solid transparent',
        borderLeft: '8px solid transparent',
        borderTop: '8px solid transparent',
        zIndex: 10
    }
  },
  toggleButton: {
    padding:5,
    borderRadius: 3,
    minWidth: 'auto',
  },
  avatar: {
    marginLeft: 12,
    height: 30,
    width: 30,
    borderRadius: 3,
  },
  dropdownIcon: {
    color: theme.palette.common.white,
  },
  logo: {
    maxHeight: 30,
  },
  label: {
    color: theme.palette.primary.main,
    textTransform: 'none'
  },
  paper: {
    boxShadow: '2px 7px 10px rgba(0, 0, 0, .1)'
  },
  menu: {
    minWidth: 180,
    padding: 12
  },
  divider: {
    right: 0,
    position: 'static',
    left: 0,
    top: 71
  },
  menuItem: {
    padding: 0,
    height: 'auto',
    fontSize: 15
  },
  listMenu: {
    minWidth: 300,
    paddingLeft: 0
  },
  menulink: {
    textDecoration: 'none'
  },
  menuItemLink: {
    display: 'block',
    padding: '12px 6px',
    color: '#000',
    textDecoration: 'none',
    '&.active': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      textDecoration: 'none',
    }
  },
  rightAlign: {
    textAlign: 'right'
  },
  profileLink: {
    textDecoration:'none'
  },
  headerButton: {
    color: theme.palette.primary.main,
    fontSize:16
  },
  headerIcon:{
    marginRight:12,
    height: 18,
    width: 'auto'
  },
  menuIcon: {
    maxWidth:36,
    maxHeight:36,
    '& $img': {
      maxWidth:'100%',
      height: 'auto'
    }
  }
});

const timeoutLength = 10
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      menuOpen: null,
      user: null,
      anchorEl: null,
      // Keep track of whether the mouse is over the button or menu
      mouseOverButton: false,
      mouseOverMenu: false,
      modalOpen: false,
      modalMessage: '',
    };
    this.modal = React.createRef();
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  enterButton = (event, target) => {
    this.setState({ 
      mouseOverButton: true,
      anchorEl: event.currentTarget,
      menuOpen: target
    });
  }

  leaveButton = () => {
    // Set a timeout so that the menu doesn't close before the user has time to
    // move their mouse over it
    setTimeout(() => {
      this.closemenu()
    }, 300);
  }

  closemenu = () => {
      this.setState({ 
        mouseOverButton: false,
        anchorEl: null,
        menuOpen: null
      });
  }

  enterMenu = () => {
    this.setState({ 
      mouseOverMenu: true
    });
  }

  leaveMenu = () => {
     setTimeout(() => {
      this.setState({
        mouseOverMenu: false,
        anchorEl: null,
        menuOpen: null
      });
     }, timeoutLength);
  }

  handleMenuClick = (event, target) => {
    this.setState({ anchorEl: event.currentTarget, menuOpen: target });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, menuOpen: null });
  };

  // Opens the modal window to view your profile
  handleProfileClick = () => {
    this.modal.current.handleMenuClickOpen();
    this.setState({ open: false });
  };

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  render() {
    const { classes } = this.props;
    const { open, user, anchorEl, menuOpen, mouseOverButton, mouseOverMenu } = this.state;
    // for cheching button or menu mouse enter
    const opened = mouseOverMenu || mouseOverButton
    const id = menuOpen ? 'simple-popper' : null;
    return (
      <>
      <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
      <AppBar position="fixed" onMouseLeave={this.leaveButton} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
            <Grid container alignItems={'center'}>
              <Grid item xs={12} sm={6}>
                <NavLink exact to="/">
                  <img src={logo} className={classes.logo} alt="logo" />
                </NavLink>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.rightAlign}>
                <Grid container alignItems={'center'} justify={'flex-end'}>
                  <Grid item sm={3}>
                    <IconButton
                      aria-describedby={id} 
                      onMouseEnter={e => this.enterButton(e, 'settings-menu')}
                      onClick={e => this.enterButton(e, 'settings-menu')}
                      className={classes.headerButton}
                    >
                      <img src={Settings} className={classes.headerIcon}/> Settings
                      {menuOpen === 'settings-menu' ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <Popper
                      transition
                      placement="bottom-end"
                      className={classes.popperContainer}
                      id="settings-menu"
                      anchorEl={anchorEl}
                      onMouseEnter={this.enterMenu}
                      onMouseLeave={this.leaveMenu}
                      open={Boolean(menuOpen === 'settings-menu') && opened}
                      onClose={this.handleClose}>
                      
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={5}>
                            <ClickAwayListener onClickAway={this.handleClose}>
                              <Paper>
                                  <NavLink style={{textDecoration: 'none', outline : 'none'}} to="/important-information">
                                    <ListItem
                                      className={classes.listMenu}
                                      onClick={this.handleClose}
                                      >
                                      <ListItemIcon className={classes.menuIcon}>
                                        <img src={ImportantInformation} />
                                      </ListItemIcon>
                                      <ListItemText classes={{ primary: classes.primary }} inset primary="Important Information" secondary="Manage information" />
                                    </ListItem>
                                  </NavLink>
                                  <Divider light className={classes.divider}/>
                                  <NavLink style={{textDecoration: 'none', outline : 'none'}} to="/introductions">
                                    <ListItem
                                      className={classes.listMenu}
                                      onClick={this.handleClose}
                                      >
                                      <ListItemIcon className={classes.menuIcon}>
                                        <img src={ImportantInformation} />
                                      </ListItemIcon>
                                      <ListItemText classes={{ primary: classes.primary }} inset primary="Introduction" secondary="Manage introductions" />
                                    </ListItem>
                                  </NavLink>
                                  <Divider light className={classes.divider}/>
                                  <NavLink style={{textDecoration: 'none', outline : 'none'}} to="/emails">
                                    <ListItem
                                      className={classes.listMenu}
                                      onClick={this.handleClose}
                                      >
                                      <ListItemIcon className={classes.menuIcon}>
                                        <img src={ImportantInformation} />
                                      </ListItemIcon>
                                      <ListItemText classes={{ primary: classes.primary }} inset primary="Send email" secondary="Manage emails" />
                                    </ListItem>
                                  </NavLink>
                                  <Divider light className={classes.divider}/>
                                  <NavLink to="/summary-files" style={{textDecoration: 'none', outline : 'none'}}>
                                    <ListItem 
                                        className={classes.listMenu}
                                        onClick={this.handleClose} 
                                        >
                                        <ListItemIcon className={classes.menuIcon}>
                                          <img src={Summaries} />
                                        </ListItemIcon>
                                        <ListItemText classes={{ primary: classes.primary }} inset primary="Summary Files" secondary="Manage files" />
                                    </ListItem>
                                  </NavLink>
                                  <Divider light className={classes.divider}/>
                                  <NavLink to="/templates" style={{textDecoration: 'none', outline : 'none'}}>
                                    <ListItem 
                                        className={classes.listMenu}
                                        onClick={this.handleClose} 
                                        >
                                        <ListItemIcon className={classes.menuIcon}>
                                          <img src={Summaries} />
                                        </ListItemIcon>
                                        <ListItemText classes={{ primary: classes.primary }} inset primary="Templates" secondary="Manage templates" />
                                    </ListItem>
                                  </NavLink>
                                </Paper>
                            </ClickAwayListener>
                          </Fade>
                        )}
                    </Popper>
                  </Grid>
                  <Grid item sm={3}>
                    <IconButton
                      aria-describedby={id} variant="contained"
                      onClick={e => this.enterButton(e, 'user-menu')}
                      onMouseEnter={e => this.enterButton(e, 'user-menu')}
                      className={classes.headerButton}
                    >
                      <img src={UserGroup} className={classes.headerIcon}/> Users &amp; Groups
                      {menuOpen === 'user-menu' ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <Popper
                      transition
                      placement="bottom-end"
                      id="simple-menu"
                      className={classes.popperContainer}
                      anchorEl={anchorEl}
                      onMouseEnter={this.enterMenu}
                      onMouseLeave={this.leaveMenu}
                      open={Boolean(menuOpen === 'user-menu') && opened}
                      onClose={this.handleClose}
                    >
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={5}>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <Paper>
                              <NavLink to="/groups" style={{textDecoration: 'none', outline : 'none'}}>
                                <ListItem
                                  className={classes.listMenu} 
                                  onClick={this.handleClose}
                                >
                                  <ListItemIcon className={classes.menuIcon}>
                                    <img src={Authentication} />
                                  </ListItemIcon>
                                  <ListItemText classes={{ primary: classes.primary }} inset primary="Authentication and authorization" secondary="Manage groups" />
                                </ListItem>
                              </NavLink>
                              <Divider light className={classes.divider}/>
                              <NavLink to="/users" style={{textDecoration: 'none', outline : 'none'}}>
                                <ListItem
                                  className={classes.listMenu} 
                                  onClick={this.handleClose}
                                >
                                  <ListItemIcon className={classes.menuIcon}>
                                    <img src={UserManagement} />
                                  </ListItemIcon>
                                  <ListItemText classes={{ primary: classes.primary }} inset primary="User Management" secondary="Manage user accounts" />
                                </ListItem>
                              </NavLink>
                             </Paper>
                          </ClickAwayListener>
                        </Fade>
                      )}
                    </Popper>
                  </Grid>
                {user &&
                  <Grid item sm={3}>
                  <NavLink className={classes.profileLink}>
                    <Button
                      className={classes.toggleButton}
                    >
                      <Typography component="p" variant="body1" className={classes.label}>
                        {user.name}
                      </Typography>
                      <Avatar alt="Profile Picture" src={user.image ? user.image : 'https://via.placeholder.com/40x40'} className={classes.avatar} />
                    </Button>
                    </NavLink>
                  </Grid>
                }
                </Grid>
              </Grid>
            </Grid>
        </Toolbar>

      </AppBar>
      </>
    );
  }

  async componentDidMount() {
    // Load async data.
    // let userData = await API.get('/profile/')
    //   .catch((error) => {
    //     this.setState(state => ({
    //       modalOpen: true,
    //       modalMessage: error.response.data.error_message
    //     }))
    //   });
    let userData = ProfileService.getProfileData()

    // Parse the results
    // userData = userData.data.profile;

    // Set the projects
    this.setState({
      ...this.state, ...{
        user: userData,
      }
    });
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
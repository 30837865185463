import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Typography, Button, Link, Paper, Toolbar, TextField } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    width: '100%',
  },
  downloadcell: {
    borderRight: 'none',
    borderBottomWidth: 2,
    paddingTop:theme.spacing.unit *1,    
    paddingBottom:theme.spacing.unit *1,
  },
  iconButton: {
    padding: 10,
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: 12,
    borderRadius: 5,
    marginLeft: theme.spacing.unit * 1,
    fontWeight: 500,
    fontFamily: "Calibri, sans-serif",
    display: 'inline-block'
  }, 
  icon: {
    marginRight: theme.spacing.unit *1
  },
  uppercase: {
    textTransform: 'uppercase'
  }
});

let id = 0;
function createData(name, file_type, link) {
  id += 1;
  return { id, name, file_type, link };
}

const rows = [
  createData('Document Name Goes Here', 'pdf', 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'),
  createData('Document Name Goes Here', 'pdf', 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'),
  createData('Document Name Goes Here', 'pdf', 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'),
  createData('Document Name Goes Here', 'pdf', 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'),
  createData('Document Name Goes Here', 'pdf', 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'),
  createData('Document Name Goes Here', 'pdf', 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'),
];

function DownloadList(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row" className={classes.downloadcell}>
                {row.name}
              </TableCell>
              <TableCell className={classes.downloadcell + ' ' + classes.uppercase}>{row.file_type}</TableCell>
              <TableCell className={classes.downloadcell} align="right">
                <IconButton className={classes.iconButton} aria-label="Download" component={Link} to={row.link}>
                  <GetAppIcon className={classes.icon}/> Download
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

DownloadList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DownloadList);

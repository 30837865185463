import React from 'react';
import moment from 'moment';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AutoComplete from '../Fields/AutoComplete';
import BuildIcon from '@material-ui/icons/Build';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import Collapse from '@material-ui/core/Collapse';

import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

const styles = theme => ({
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit * 1,
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  label:  {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  build: {
    marginRight: 15,
    height: 22,
    width: 'auto', 
    color: '#445f6d',
  },
  removebutton: {
    border: '2px solid #1b4059',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    minWidth: 'auto',
    borderRadius: 3,
    textTransform: 'initial',
    fontSize: 14,
    padding:10,
    fontFamily: "Calibri, sans-serif"
  },
  scheduling: {
    backgroundColor:'#f7f7f7',
    width: '100%',
    borderRadius: 3,
    padding: 15,
  },
  close: {
    float: 'right',
  },
  disabled: {
    backgroundColor: '#CCC'
  },
  warning: {
    backgroundColor:'#ffe792',
    fontSize: 14,
    fontFamily: "Calibri, sans-serif",
    padding: 10,
    alignContent: 'center',
    display: 'flex',
    borderRadius: 3
  },
  warningicon: {
    color:'#ff9800',
    marginRight: 15,
  },
  checkbox: {
    marginRight: 10,
  }
});

class TimeSchedulingForm extends React.Component {
  // const classes = useStyles();

  constructor(props) {
    super(props);
    let dates = ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
        selectedDates = [],
        times = {};
    for (let date of dates) {
      selectedDates.push(date);
    }
    for (let selectedDate of selectedDates) {
      times[selectedDate] = {
          'from': '9:00',
          'to': '17:00'
      }
    }
    this.state = {
      dates: dates,
      selectedDates: selectedDates,
      times: {}
    };
  }

  handleChange(date) {
    let selectedDates = this.state.selectedDates;
    if (selectedDates.indexOf(date) >= 0) {
      selectedDates.splice(selectedDates.indexOf(date), 1);
      } else {
      selectedDates.push(date);
    }
    this.setState({selectedDates: selectedDates});
  }

  closeContainer(e) {
    this.props.closeContainer(e);
  }

  showAdvancedCalendarView(e) {
    this.props.showAdvancedCalendarView(e);
  }

  render() {
    const { classes } = this.props;
    const { dates, selectedDates, times } = this.state;
    return (
      <React.Fragment>
        <div className={classes.scheduling}>
          <IconButton className={classes.close} onClick={e => this.closeContainer(e)}>
            <CloseIcon />
          </IconButton>
          <Grid container>
            <Grid item sm={8}>
              {dates && 
                <React.Fragment>
                  {dates.map((date, index) => 
                    <Grid container spacing={8} alignItems="center">
                      <Grid item sm={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDates.indexOf(date)>=0}
                                onChange={e => {this.handleChange(date)}}
                                value={date}
                                className={classes.checkbox}
                              />
                            }
                            label={date}
                          />
                      </Grid>
                      {selectedDates.indexOf(date)>=0 && 
                        <React.Fragment>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              fullWidth
                              id="name_of_event"
                              name="name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              required
                              fullWidth
                              id="name_of_event"
                              name="name"
                            />
                          </Grid>
                        </React.Fragment>
                      }
                      {selectedDates.indexOf(date)<0 &&
                        <React.Fragment>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              disabled
                              InputProps={{
                                className: classes.disabled
                              }}
                              fullWidth
                              id="name_of_event"
                              name="name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextField
                              disabled
                              InputProps={{
                                className: classes.disabled
                              }}
                              fullWidth
                              id="name_of_event"
                              name="name"
                            />
                          </Grid>
                        </React.Fragment>
                      }
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={8}>
                        <IconButton className={classes.removebutton} onClick={e => {this.showAdvancedCalendarView(e)}}>
                          <BuildIcon className={classes.build}/> Customise individual dates
                        </IconButton>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
            </Grid>
          </Grid>
          <Grid container>
            <Grid item sm={8}></Grid>
            <Grid item sm={4} className={classes.warning}>
               <WarningIcon className={classes.warningicon}/> 
                <span>Custom changes have been made</span>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

TimeSchedulingForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TimeSchedulingForm);
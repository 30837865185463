import React from 'react';

// COMPONENTS
import ChipInput from 'material-ui-chip-input'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  root: {},
  modaltitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: "Calibri, sans-serif",
    fontSize: 30,
  },
  closeButton: {
    float: 'right'
  },
  row: {
    marginBottom: 30,
    alignContent: 'center',
    '&:last-of-type': {
      marginBottom:0
    }
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  icon: {
    marginRight: 15,
    height: 16,
    width: 'auto', 
  },  
  addIcon: {
    marginRight: 15,
    height: 16,
    width: 'auto', 
    border:  '2px solid #1b4059',
    borderRadius: 3
  },
  transButton: {
    padding: 10,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontSize: 12,
    borderRadius: 3,
    fontWeight: 500,
    fontFamily: "Calibri, sans-serif",
  },  
  removeButton: {
    padding: 10,
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontSize: 12,
    borderRadius: 3,
    fontWeight: 500,
    fontFamily: "Calibri, sans-serif",
    border:'2px solid #1b4059',
    marginLeft: theme.spacing.unit *2
  },
  sensorSelect: {
    height: 40,
    backgroundColor:'#e2eef6',
    padding: 10,
    color: '#333',
    borderRadius:3,
    width:'100%'
  },
  newProjectButton: {
    borderRadius: 3,
    height: 40, 
    fontSize: '14px',
    textTransform: 'none',
    minWidth: 'auto',
    padding: '0 15px',
    backgroundColor: '#1b4059',
    color: '#fff',
    '&:hover':{
      backgroundColor: '#445f6d',
    }
  },
  dialogActions: {
      justifyContent: 'left'
  }
});

class EditSensorModal extends React.Component {
  state = {
    open: false,
    start: '09:00',
    end: '17:00',
    tags: ['Old tag name','Old tag name 2', 'Another old tag name']
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { participants, start, end, tags } = this.state;
    
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="lg"
        >
          <DialogTitle id="form-dialog-title" disableTypography={true} className={classes.modaltitle}>
            Edit Sensor
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              overflow: 'visible',
            }} >
            <Grid container className={classes.row}>
              <Grid item xs={4} className={classes.label}>MAC Address</Grid>
              <Grid item xs={6}>
                <TextField
                  id="start_time"
                  fullWidth
                  placeholder="Mac Address"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs={4} className={classes.label}>Sensor Name</Grid>
              <Grid item xs={6} className={classes.label}>
                <TextField
                  id="start_time"
                  fullWidth
                  placeholder="Last used name"
                  disabled
                />
                <br />
                <IconButton  className={classes.transButton}>
                  <EditIcon className={classes.icon} /> Delete and rename
                </IconButton>
              </Grid>
              <Grid item xs={4}>&nbsp;</Grid>

            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs={4} className={classes.label}>Tags</Grid>
              <Grid item xs={8}>
                {tags.map((label, index) =>
                  <Grid container alignContent="center"  className={classes.row}>
                    <Grid item xs={9}>
                      <TextField
                        id="start_time"
                        fullWidth
                        placeholder="Last used name"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton color="primary" className={classes.removeButton}>
                        <DeleteIcon className={classes.icon}/> Remove
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={4}>&nbsp;</Grid>
              <Grid item xs={8}>
                <IconButton className={classes.transButton}>
                  <AddIcon className={classes.addIcon}/>
                  Add new tag
                </IconButton>
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs={4} className={classes.label}>Status</Grid>
              <Grid item xs={6}>
                <Select
                    value={this.state.status}
                    onChange={this.handleChange}
                    input={<FilledInput name="status" id="filled-age-simple" />}
                    className={classes.sensorSelect}
                    disabled
                  >
                    <MenuItem value="">
                      <em>Active</em>
                    </MenuItem>
                    <MenuItem value={10}>Inactive</MenuItem>
                  </Select>
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs={4} className={classes.label}>Distance parameter</Grid>
              <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.checkedCreate}
                        value="checkedCreate"
                        color="primary"
                      />
                    }
                    label="Change range boundaries"
                  />
              </Grid>
            </Grid>
            <Grid container className={classes.row}>
              <Grid item xs={4}></Grid>
              <Grid item xs={6}>
                <Grid container spacing={16}>
                  <Grid item xs={4}>
                    <Typography variant="body1">Engaged</Typography><br />
                    <Select
                        value={this.state.status}
                        onChange={this.handleChange}
                        input={<FilledInput name="status" id="filled-age-simple" />}
                        className={classes.sensorSelect}
                      >
                        <MenuItem value=""><em>2m/6ft</em></MenuItem>
                        <MenuItem value={3}>3m/9ft</MenuItem>
                        <MenuItem value={4}>4m/13ft</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                    <Typography variant="body1">Passerby</Typography><br />
                    <Select
                        value={this.state.status}
                        onChange={this.handleChange}
                        input={<FilledInput name="status" id="filled-age-simple" />}
                        className={classes.sensorSelect}
                      >
                        <MenuItem value=""><em>2m/6ft</em></MenuItem>
                        <MenuItem value={3}>3m/9ft</MenuItem>
                        <MenuItem value={4}>4m/13ft</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs={4}>
                    <Typography variant="body1">Total Reach</Typography><br />
                    <Select
                        value={this.state.status}
                        onChange={this.handleChange}
                        input={<FilledInput name="status" id="filled-age-simple" />}
                        className={classes.sensorSelect}
                      >
                        <MenuItem value=""><em>2m/6ft</em></MenuItem>
                        <MenuItem value={3}>3m/9ft</MenuItem>
                        <MenuItem value={4}>4m/13ft</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button  color="primary" onClick={this.handleClose} className={classes.newProjectButton}>
              Add Sensor
            </Button>
            <IconButton color="primary" onClick={this.handleClose} className={classes.transButton}>
              Cancel
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  
}

EditSensorModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditSensorModal);
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import IconHistory from '../../assets/icon-history.png'
import CheckCircleOutlineIcon from '../../assets/icon-check-circle.png';
import CheckIcon from '../../assets/icon-check.png';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextareaAutosize from 'react-textarea-autosize';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import IntroductionItem from '../../pages/Introduction/Item';
import ImportantInformationItem from '../../pages/ImportantInformation/Item';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import AutoComplete from '../Fields/AutoComplete';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

function TabContainer(props) {
  return (
      props.children
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  base: {},
  root: {},
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%' // Fix IE 11 issue.
  },
  styleForRightBorder: {
    borderRight: '1px solid #e7edee'
  },

  styleForLeftBorder: {
    borderLeft: '1px solid #e7edee'
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0',
    paddingTop: '0',
    height: '100%',
    paddingBottom: '0',
    "& > div": {
      padding: 0
    }
  },
  group: {
  },
  tableRow: {
    // height: 'auto !important',
  },
  textarea: {
    padding: 0,
    height: '100%',
    width: '100%',
    verticalAlign: 'middle',
    border: 'none',
    fontFamily: 'inherit',
    fontSize: '16px !important',
    color: '#27498f',
    "& div": {
      padding: 0,
      height: '100%',
      width: '100%'
    },
    "& div textarea": {
      // paddingTop: 10,
      // paddingBottom: 10,
      height: '100%',
      padding: 0,
      height: '100%',
      fontSize: '16px !important',
      border: 'none !important',
      boxShadow: '0 0 1px #e7edee',
      lineHeight: '1.2rem !important',
      wordWrap: 'break-word !important',
      whiteSpace: 'pre-wrap !important'
    }
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  paper: {
    position: 'relative',
    overflow: 'scroll'
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,   
    paddingBottom: theme.spacing.unit*1,   
    paddingLeft: theme.spacing.unit*2, 
    paddingRight: theme.spacing.unit*2,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#27cfda'
    }
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
  benefitTableHead: {
    backgroundColor: theme.palette.background.default,
    '& tr': {
      '& th': {
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        fontWeight: 600
      }
    }
  },
  textareaInCell: {
    cursor: 'pointer',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '16px !important',
    lineHeight: '1.2rem',
    height: '60px',
    minWidth: 300,
    padding: '0 10px',
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  vendorTextareaInCell: {
    cursor: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '16px !important',
    lineHeight: '1.2rem',
    height: '60px',
    minWidth: 300,
    padding: '0 10px',
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  checkIcon: {
    width: 18,
    height: 'auto'
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formRow: {
    marginBottom: theme.spacing.unit*3
  },
  summarySelect: {
    width: '100%',
    height: 40,
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  inputBlockWrapper: {
    flexDirection: 'column', 
    display: 'flex', 
    justifyContent: 'space-between'
  },
  separatedCells: {
    minWidth: 300,
    padding: '0 10px'
  },
  closeButton: {
    float: 'right'
  },
  hidden: {
    display: 'none',
  }
});

let baseLink = "/plans/";

class TabForm extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {
      formFields: props.formFields,
      item: props.item,
      benefits: props.benefits,
      fieldObj: props.fieldObj,
      benefitCellList: ['benefit_lang','benefit_in','benefit_out','sbc_lang','benefit_in2','benefit_in3','benefit_in4','benefit_out2',],
      editedText: "",
      edit: false,
      currentCellId: null,
      currentCellTitle: null,
      loaded: false,
      modalOpen: false,
      modalValues: null,
      showAutoComplete: true
    }
  }

  // handle benefit update
  handleCell = (id, title, text) => {
    this.setState({
      editedText: text,
      edit: true,
      currentCellId: id,
      currentCellTitle: title
    })
  }

  modifyTextInCell = (e) => {
    this.setState({
      editedText: e.target.value
    })
  }

  saveChanges = () => {
    let benefits = this.state.benefits;
    for (let key in benefits) {
      if(benefits[key].benefit_id === this.state.currentCellId) {
        benefits[key][this.state.currentCellTitle] = this.state.editedText;
      }
    }
    this.setState({
      benefits,
      edit: false,
      currentCellId: null,
      currentCellTitle: null,
    })
  }

  clearChanges = () => {
    this.setState({
      edit: false,
      currentCellId: null,
      currentCellTitle: null,
    })
  }

  handleToggleVisible = (benefitId) => {
    let benefits = this.state.benefits;
    for (let key in benefits) {
      if(benefits[key].benefit_id === benefitId) {
        benefits[key].is_visible = !benefits[key].is_visible;
      }
    }
    this.setState({
      benefits,
      edit: false,
      currentCellId: null,
      currentCellTitle: null
    })
  }

  handleChange = (key, e) => {
    let value = e.target.value,
        {item} = this.state;
    item[key] = value;
    this.setState({item});
  }

  handleOnCheckboxChange = (key, e) => {
    let checked = e.target.checked,
        {item} = this.state;
    item[key] = checked;
    this.setState({item});
  }

  handleAutoCompleteChange = (key, value, isModal) => {
    let {item} = this.state;
    if (key == 'introductions' || key == 'introduction') {
      item[key] = value;
      key = "introduction";
    } else {
      item[key] = value;
      key = "important_information";
    }
    item[key] = value;
    if (isModal) {
      this.setState({item, modalOpen: false, modalValues:null, showAutoComplete: false}, () => {
        setTimeout(() => {this.setState({showAutoComplete: true})}, 50);
      });
    } else {
      this.setState({item, modalOpen: false, modalValues:null});
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.props.clearChanges(e);
    }
  }

  moveCaretAtEnd(e) {
    var temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  handleModalOpen = (modalType, editType, value) => {
    let modalTitle = (modalType == 'introductions') ? 'Introduction' : "Important Information";
    this.setState({
      modalOpen: true,
      modalValues: {
        'type': modalType,
        'editType': editType,
        'value': value,
        'title': modalTitle
      }
    })
  }

  handleModalClose = () => {
    this.setState({modalOpen: false});
  }
  getModalContent = () => {
    let { modalOpen, modalValues } = this.state;
    if (!modalOpen) {
      return;
    }
    switch(modalValues.type) {
      case "important-information":
        return <ImportantInformationItem display="modal" editType={modalValues.editType} value={modalValues.value} handleUpdate={(value)=>this.handleAutoCompleteChange(modalValues.type, value, true)} />;
      case "introductions":
        return <IntroductionItem display="modal" editType={modalValues.editType} value={modalValues.value} handleUpdate={(value)=>this.handleAutoCompleteChange(modalValues.type, value, true)} />;
    }
  }

  

  render() {
    const { classes, isNotEditor } = this.props;
    const { formFields, item, benefits, edit, editedText, currentCellTitle, currentCellId, fieldObj, showAutoComplete } = this.state;
    const benefitCellList = this.state.benefitCellList

    let isBenefit = false,
        formName = null,
        formOptions = null;
    if (formFields.length) {
      formName = formFields[0];
      formOptions = formFields[1].fields;
      if (formFields.length > 2 && formFields[2] == "benefit") {
        isBenefit = true;
      }
    }
    return (
      <React.Fragment>
        {formName &&
          <React.Fragment>
            {isBenefit && 
              <Grid>
                <TabContainer>
                <Table class="editableTable">
                  <TableHead className={classes.benefitTableHead}>
                    <TableRow>
                        <TableCell style={{width: '14%'}} className={classes.styleForRightBorder}>Benefit Name</TableCell>
                        <TableCell style={{textAlign: 'center', width: '4%'}}><img className={classes.checkIcon} src={CheckCircleOutlineIcon}/></TableCell>
                        <TableCell style={{paddingLeft: 30, width: '16%'}} className={classes.styleForLeftBorder}>Lang</TableCell>
                        <TableCell className={classes.separatedCells} style={{width: '350'}}>In</TableCell>                   
                        <TableCell className={classes.separatedCells} style={{width: '350'}}>Out</TableCell>                   
                        <TableCell className={classes.separatedCells} style={{width: '350'}}>SBC Lang</TableCell> 
                        <TableCell className={classes.separatedCells} style={{width: '350'}}>In2</TableCell>                    
                        <TableCell className={classes.separatedCells} style={{width: '350'}}>In3</TableCell>                    
                        <TableCell className={classes.separatedCells} style={{width: '350'}}>In4</TableCell>
                        <TableCell className={classes.separatedCells} style={{width: '350'}}>Out2</TableCell> 
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      formOptions.map(option => {
                        let benefit = null,
                        benefitToUse = benefits.filter((val)=> { return val.benefit_id == option });
                        if (benefitToUse.length) {
                          benefit = benefitToUse[0];
                        }
                        return (
                          <React.Fragment>
                            {benefit && 
                              <TableRow key={benefit.id} className={classes.tableRow}>
                              <TableCell style={{width: '14%', maxWidth: '350px', whiteSpace: 'normal'}} className={classes.styleForRightBorder}>{benefit.label}</TableCell>
                              <TableCell style={{width: '14%'}} style={{textAlign: 'center'}} className={classes.styleForRightBorder} onClick={() => this.handleToggleVisible(benefit.benefit_id)}>
                                {benefit.is_visible && <img className={classes.checkIcon} src={CheckIcon}/>}
                              </TableCell>

                              {benefitCellList.map(key => {
                                  const refText = (edit && (currentCellId === benefit.benefit_id) && (currentCellTitle === key)) ? input => input && input.focus() : null;
                                  return (edit && (currentCellId === benefit.benefit_id) && (currentCellTitle === key))?
                                    <TableCell
                                      paddingNone
                                      className={isNotEditor ? classes.vendorTextareaInCell : classes.textareaInCell}
                                      style={key === 'lang'? {paddingLeft: 30, width: '14%'}: {}}
                                    >
                                      <TextareaAutosize
                                          className={classes.textarea}
                                          onBlur = {this.saveChanges}
                                          onFocus = {this.moveCaretAtEnd}
                                          onKeyDown={this._handleKeyDown}
                                          onChange = {(e) => this.modifyTextInCell(e, item.id, key)}
                                          name={key}
                                          value = {editedText} 
                                          multiline={true}
                                          ref={refText}
                                          disabled={isNotEditor}
                                      />
                                    </TableCell>
                                    :
                                    <TableCell
                                      className={isNotEditor ? classes.vendorTextareaInCell : classes.textareaInCell}
                                      style={key === 'lang'? {paddingLeft: 30, width: '14%'}: {}}
                                      onClick={() => this.handleCell(benefit.benefit_id, key, benefit[key])}>{benefit[key]}
                                    </TableCell>
                                  })
                              }
                            </TableRow>
                          }
                        </React.Fragment>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </TabContainer>
              </Grid>
            }
            {!isBenefit &&
              <React.Fragment>
                <Grid container>
                  <Grid item xs={12} sm={10}>
                    <Grid container className={classes.formRow} spacing={40}>
                      {formOptions.map(option => {
                        let value = item[option],
                            field = fieldObj[option],
                            fieldToShow = "text",
                            autocompleteType = "important-information";
                            if (field.choices.length) {
                              fieldToShow = "select";
                            }
                            if (field.type =="BooleanField") {
                              fieldToShow = "checkbox";
                            }
                            if (field.type =="ForeignKey") {
                              fieldToShow = "autocomplete";
                              if (option == "introduction") {
                                autocompleteType = "introductions";
                              }
                            }
                            console.log(option, autocompleteType, value);
                        return (
                          <Grid item xs={12} sm={6} px={6} className={classes.inputBlockWrapper}>
                            <Grid item xs={12} sm={12} className="formLabel">{field.label} {field.type == "ForeignKey" && 
                              <React.Fragment>
                                <IconButton
                                  className={isNotEditor ? classes.hidden : classes.transButton}
                                  onClick={(e) => {this.handleModalOpen(autocompleteType, 'add')}}
                                >
                                  <AddIcon className={classes.addIcon} />
                                </IconButton>
                                {value != '' && 
                                  <IconButton
                                    className={isNotEditor ? classes.hidden : classes.transButton}
                                    onClick={(e) => {this.handleModalOpen(autocompleteType, 'edit', value)}}
                                  >
                                    <EditIcon className={classes.addIcon} />
                                  </IconButton>
                                }
                              </React.Fragment>
                            }</Grid>
                            <Grid item xs={12} sm={12} className={classes.inputWrapper}>
                              {fieldToShow == 'select' && 
                                <Select
                                    name={option}
                                    value={value}
                                    onChange={(e) => this.handleChange(option, e)}
                                    className={classes.summarySelect}
                                    disabled={isNotEditor}
                                >
                                  {field.choices.map((obj, idx) => {
                                    let value = obj[0],
                                        label = obj[1];
                                    return (
                                      <MenuItem value={value}>{label}</MenuItem>
                                    )
                                  })}
                                </Select>
                              }
                              {fieldToShow == 'checkbox' && 
                                <div className={classes.checkboxInline}>
                                    <Checkbox
                                      disabled={isNotEditor}
                                      checked={value}
                                      onChange={(e) => this.handleOnCheckboxChange(option, e)}
                                    />
                                  </div>                       
                              }
                              {fieldToShow == 'autocomplete' && showAutoComplete && 
                                <AutoComplete
                                  isNotEditor={isNotEditor}
                                  type={autocompleteType}
                                  value={value}
                                  update={(value) => this.handleAutoCompleteChange(option, value) }
                                />
                              }
                              {fieldToShow == 'text' && 
                                <TextField
                                  value={value}
                                  fullWidth
                                  id={option}
                                  name={option}
                                  onChange={(e) => this.handleChange(option, e)}
                                  disabled={isNotEditor}
                                />
                              }
                            </Grid>
                          </Grid>  
                        )
                      })}
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              }
          </React.Fragment>
        }
        <Dialog
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          aria-labelledby="form-dialog-title"
          maxWidth="xl"
          className={classes.dialog}
        >
          <DialogTitle id="form-dialog-title" disableTypography={true} className={classes.modaltitle}>{this.state.modalValues != null && this.state.modalValues.title} <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleModalClose}>
              <CloseIcon />
            </IconButton></DialogTitle>
          <DialogContent
            style={{
              overflow: 'visible',
              paddingBottom: 0
            }} >
            {this.getModalContent()}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    if(oldProps.formFields !== newProps.formFields) {
        this.setState({
          formFields: newProps.formFields,
          item: newProps.item
        });
    }
  }
}

TabForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabForm);
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconHistory from '../../assets/icon-history.png'
import CheckCircleOutlineIcon from '../../assets/icon-check-circle.png';
import CheckIcon from '../../assets/icon-check.png'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Select, OutlinedInput, Divider } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import AppModal from "../../components/Modals/AppModal"

import EditToolbar from '../../components/Fields/EditToolbar';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CalendarIcon from '../../assets/icon-calendar.png';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowDown';

import ChangePasswordModal from './ChangePassswordModal'

import API from "../../utils/API";

function TabContainer(props) {
  return (
      props.children
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  base: {},
  root: {
    // padding: theme.spacing.unit * 2
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: (theme.spacing.unit * 8, theme.spacing.unit * 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing.unit * 1,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: (theme.spacing.unit * 3, theme.spacing.unit * 0, theme.spacing.unit * 2),
  },
  formControl: {
    margin: '12px 0'
  },
  group: {
  },
  label: {
    textTransform: 'uppercase',
    alignSelf: 'center',
    fontSize: 12,
    color: '#6d6f72'
  },
  subTitleText: {
    color: '#aebcdc',
    fontFamily: 'Calibri sans-serif',
    fontSize: 14,
    fontWeight: 400
  },
  dropdownIcon: {
    color: theme.palette.primary.light,
    marginLeft: theme.spacing.unit*1,
    display: 'none'
  },
  labelForDatePicker: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Calibri',
    fontSize: 18,
    fontWeight: 40,
    color: theme.palette.primary.main
  },
  labelInRequestTab: {
    fontSize: 18,
    fontWeight: 40,
    color: theme.palette.primary.main
  },
  blueButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: theme.spacing.unit*1,
    paddingBottom: theme.spacing.unit*1,
    paddingLeft: theme.spacing.unit*2,
    paddingRight: theme.spacing.unit*2,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit*0.5,
    borderRadius: 24,
    fontSize: 18,
    width: 150,
    justifyContent: 'space-between',
    fontSize: 18,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff'
    }
  },
  pickerContainer: {
  },
  previousButton: {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent'
    }
  },
  buttonIcon: {
    width: 19,
    height: 17
  },
  checkIcon: {
    width: 18,
    height: 'auto'
  },
  titleOfRow: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 20
  },
  titleOfCheckBox: {
    fontWeight: 400,
    fontSize: 18
  },
  checkBoxContainer: {
    borderBottom: '1px solid #e7edee'
  },
  paperContainer: {
    position: 'relative'
  },
  paperTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit*2,
  },
  formLabelForRequest: {
    color: theme.palette.primary.light,
    fontWeight: 'bold',
  },
  formRow: {
    marginBottom: theme.spacing.unit*3,
    fontSize: 17,
    color: theme.palette.primary.main
  },
  summarySelect: {
    width: '100%',
    height: 40,
  },
});

let baseLink = "/users/";
class UsersItem extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    let item = {},
        options = {
          'actions': ['save']
        };
    if (this.props.match.params.id) {
      item = null;
      options['actions'].push('delete');
    }
    this.state = {
      item: item,
      currentTab: 0,
      changePasswordClicked: false,
      value: 'true',
      options: options,
      groups: ["Editor","Underwriter","Vendor"],
      modalOpen: false,
      modalMessage: ''
    };
    if (!item) {
      setTimeout(() => this.getData());
    }
  }

  getUrl = () => {
    let url = baseLink;
    if (this.props.match.params.id) {
      url += this.props.match.params.id;
    }
    return url;
  }

  getPasswordUrl = () => {
    let url = this.getUrl();
    return url + "/password/";
  }

  async getData() {
    // Load async data.
    let url = this.getUrl();

    let apiData = await API.get(url)
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
    // Parse the results
    let item = apiData.data.item;

    // Set the clients
    this.setState({
      ...this.state, ...{
        item: item
      }
    });
  }

  handleAction = (action) => {
    if (action == 'save') {
      this.handleSave();
      return;
    }
    if (action == 'delete') {
      this.handleDelete();
      return;
    }
  }

  handleDelete = (e) => {
    // Load async data.
    let url = this.getUrl();
    API.delete(url)
      .then((res) => {
        this.props.history.push('/users/');
      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: error.response.data.error_message
        }))
      });
  }

  handleSave = (e) => {
    let item = this.state.item,
        url = this.getUrl(true);
    if (!this.isValidItem(item)) {
      this.setState(state => ({
        modalOpen: true,
        modalMessage: "You must complete the form before continuing"
      }))
      return;
    }
    let dict = {
      'user': item
    }
    API.post(url, dict)
      .then((res) => {
        if (res.data.success) {
        this.props.history.push(baseLink);
          } else {
            this.setState(state => ({
              modalOpen: true,
              modalMessage: "Cannot save with the current details"
            }))
        }

      })
      .catch((error) => {
        this.setState(state => ({
          modalOpen: true,
          modalMessage: "Cannot save with the current details"
        }))
      });
  }

  handleModalClose = () => {
    this.setState(state => ({
      modalOpen: false,
    }))
  }

  handleUpdate = (key, value) => {
    let item = this.state.item;
    item[key] = value;
    this.setState({ item });
  }

  handleChangePasswordModal = () => {
    this.setState({
      changePasswordClicked: true
    })
  }

  closeModal = () => {
    this.setState({
      changePasswordClicked: false
    })
  }

  handleOnCheckboxChange = (option, e) => {
    let item = this.state.item,
        value = e.target.checked;
    item[option] = value;
    this.setState({item});
  }

  handleDropdownChange = (e) => {
    let item = this.state.item;
    item[e.target.name] = e.target.value;
    this.setState({item});
  }

  isValidItem = (item) => {
    if (!item.username ||
        !item.email ||
        !item.group_name) {
      return false;
    }
    return true;
  }


  render() {
    const { classes } = this.props;
    const { options, currentTab, groups, item, changePasswordClicked } = this.state;
    let id = this.props.match.params.id;

    return (
      <div className={classes.base}>
      <AppModal open={this.state.modalOpen} textMessage={this.state.modalMessage} handleClose={this.handleModalClose}/>
      {item &&
        <Grid container component="main" alignItems={'center'} className={classes.root}>
          {changePasswordClicked &&<ChangePasswordModal changePasswordClicked = {changePasswordClicked} userId={id} close = {() => this.closeModal()}/>
          }
          <Grid item xs={9}>
            <Typography component="h2" variant="h1" className="pageTitle">
              User
              <KeyboardArrowUpIcon className={classes.dropdownIcon}/>
            </Typography>
          </Grid>
          <Grid item xs={3} align="right">
            <IconButton component={Link} to={`/users/${id}/history`} className={classes.blueButton}>
              History
              <img src={IconHistory} className={classes.buttonIcon}/>
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <form className={classes.form} noValidate>
              <Paper className={classes.paperContainer}>
                <Grid container className={classes.formcontainer}>
                  <EditToolbar options={options} handleAction={(action)=> this.handleAction(action)} />
                </Grid>
                <Divider className="searchLine searchLine__toolbar"/>
                  <Grid container spacing={24} style={{marginBottom: 0}} className={classes.formRow}>
                    <Grid item xs={12} sm={3}>
                      <Grid item xs={12} sm={12} className="formLabel">Username</Grid>
                        <TextField
                                required
                                fullWidth
                                value={item.username}
                                onChange={e => this.handleUpdate('username', e.target.value)}
                          />
                        <Grid item xs={12} sm={12} style={{marginTop: 5}} className = {classes.subTitleText}>
                          Required 150 characters or fewer, Letter, digits and @/./+/-/_only
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid container spacing={24} onClick={this.handleChangePasswordModal} style={{marginBottom: 0, cursor:'pointer'}} className={classes.formRow}>
                    <Grid item xs={12} sm={3}>
                      <Grid item xs={12} sm={12} style={{marginTop: 8}} className="formLabel">Password</Grid>
                        Change password
                      </Grid>

                      <Grid item xs={12} sm={12} className = {classes.subTitleText}>
                        Raws passwords are not stored, so there is no way
                        to see this user's password, but you can change the password using this form
                      </Grid>
                  </Grid>
                  <Divider className="searchLine__table"/>
                  <Grid container spacing={24} className={classes.formRow} style={{marginTop: 20}}>
                    <Grid item xs={12} sm={12} className={classes.titleOfRow}>Personal Info</Grid>
                    <Grid item xs={12} sm={3}>

                      <Grid item xs={12} sm={12} className="formLabel">First Name</Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                          required
                          fullWidth
                          name="first_name"
                          value={item.first_name}
                          onChange={e => this.handleUpdate('first_name', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Grid item xs={12} sm={12} className="formLabel">Last Name</Grid>
                      <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            fullWidth
                            name="last_name"
                            value={item.last_name}
                            onChange={e => this.handleUpdate('last_name', e.target.value)}
                          />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={24} style={{marginBottom: 0}} className={classes.formRow}>
                    <Grid item xs={12} sm={3}>
                      <Grid item xs={12} sm={12} className="formLabel">Email Address</Grid>
                      <Grid item xs={12} sm={12}>
                          <TextField
                              required
                              fullWidth
                              name="email"
                              value={item.email}
                              onChange={e => this.handleUpdate('email', e.target.value)}
                          />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} className = {classes.subTitleText}>
                      Last logged in {item.last_login}
                    </Grid>
                  </Grid>
                  <Divider className="searchLine__table"/>
                  <Grid container spacing={24} style={{marginTop: 20}} className={classes.formRow}>
                  <Grid item xs={12} sm={12} className={classes.titleOfRow}>Permissions</Grid>
                    <Grid item xs={12} sm={12} className={classes.checkBoxContainer}>
                      <Grid item xs={12} sm={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{marginBottom: 'auto'}}
                              checked={item.is_active}
                              onChange={(e) => this.handleOnCheckboxChange('is_active', e)}
                            />
                          }
                          label={
                              <Grid>
                                  <Typography className={classes.titleOfCheckBox}>Active</Typography>
                                  <Typography className={classes.subTitleText}>
                                    Designates whether this user should be treated as active. Unselect this instead of deleting account
                                  </Typography>
                              </Grid>
                          }
                        />
                      </Grid>
                    </Grid>

                     <Grid item xs={12} sm={12} className={classes.checkBoxContainer}>
                      <Grid item xs={12} sm={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{marginBottom: 'auto'}}
                              checked={item.is_staff}
                              value="1"
                              onChange={(e) => this.handleOnCheckboxChange('is_staff', e)}

                            />
                          }
                          label={
                              <Grid>
                                  <Typography className={classes.titleOfCheckBox}>Staff Status</Typography>
                                  <Typography className={classes.subTitleText}>
                                    Designates whether this user can log into this admin site
                                  </Typography>
                              </Grid>
                          }
                        />
                      </Grid>
                    </Grid>

                     <Grid item xs={12} sm={12} className={classes.checkBoxContainer}>
                      <Grid item xs={12} sm={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{marginBottom: 'auto'}}
                              checked={item.is_superuser}
                              value="checkedCreate"
                              onChange={(e) => this.handleOnCheckboxChange('is_superuser', e)}
                            />
                          }
                          label={
                              <Grid>
                                  <Typography className={classes.titleOfCheckBox}>Superuser Status</Typography>
                                  <Typography className={classes.subTitleText}>
                                    Designates whether this user has all permissions without explicitly assigning them
                                  </Typography>
                              </Grid>
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={24} className={classes.formRow}>
                    <Grid item xs={12} sm={12} className={classes.titleOfRow}>Groups</Grid>

                    <Grid item xs={12} sm={3}>
                      <Grid item xs={12} sm={12}>
                         <Select
                            name="group_name"
                            value={item.group_name}
                            onChange={(e) => this.handleDropdownChange(e)}
                            className={classes.summarySelect}
                          >
                            {groups.length > 0 && groups.map((group, idx) =>
                                <MenuItem value={group}>{group}</MenuItem>
                              )
                            }
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
              </Paper>
            </form>
          </Grid>
          </Grid>
        }
      </div>
    );
  }
}

UsersItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UsersItem);

import React from 'react';
import {useDropzone} from 'react-dropzone';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

function ProfileUpload(props) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone();
  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag and drop an image file here, or click<br /><br/><CloudUploadIcon/></p>
      </div>
    </section>
  );
}

export default ProfileUpload;
import React from 'react';

// COMPONENTS
import ChipInput from 'material-ui-chip-input'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import AutoComplete from '../Fields/AutoComplete';

import DeleteIcon from '@material-ui/icons/Delete';

const styles = theme => ({
  root: {},
  button: {
    height: 40,
    minWidth: 'auto',
    fontSize:14,
    fontWeight: 400,
    padding: '0 15px',
    textTransform: 'initial',
    borderRadius: 3,
    margin: '0 15px',
    '&:hover': {
      backgroundColor: '#445f6d'
    }
  },
  deleteButton: {
      border: '2px solid #1b4059',
      color: theme.palette.primary.main,
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      minWidth: 'auto',
      borderRadius: 3,
      textTransform: 'initial',
      fontSize: 14,
      padding:10,
      fontFamily: "Calibri, sans-serif"
    },
});

class EditCalendarViewModal extends React.Component {
  state = {
    open: false,
    start: '09:00',
    end: '17:00',
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { participants, start, end } = this.state;
    
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title" disableTypography={true}>
            Edit Date
            <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            style={{
              overflow: 'visible',
            }} >
            <Typography variant="body2">4 September 2019</Typography>
            <Grid container>
              <Grid item xs={12}>Select start and end times</Grid>
              <Grid item xs={6}>
                <AutoComplete list="time" />
              </Grid>
              <Grid item xs={6}>
                <AutoComplete list="time" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.handleClose} className={classes.button}>
              Update
            </Button>
            <IconButton variant="outlined" color="primary" onClick={this.handleClose} className={classes.deleteButton}>
              <DeleteIcon /> Remove Date
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  
}

EditCalendarViewModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EditCalendarViewModal);
import React, { useState } from 'react';
import { BrowserRouter, Link, Route, Switch, } from 'react-router-dom';
import './App.css';

// THEME
import CssBaseline from "@material-ui/core/CssBaseline";
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import muiTheme from './theme/muiTheme';

// COMPONENTS
import Header from './parts/Header';
import Content from './parts/Content';
import Login from './parts/login/Login';
import Logout from './parts/login/Logout';
import ForgotPassword from './parts/login/ForgotPassword';
import ResetPassword from './parts/login/ResetPassword';
import Footer from './parts/Footer';

// SERVICES
import AccountService from './utils/AccountService';
import API from "./utils/API";


class App extends React.Component {
  constructor(props) {
    let token = AccountService.returnData();
    super(props);
    this.state = {
      isLoaded: false
    };
    let validLoginAddresses = ['login','forgot-password','reset-password'],
        isValidLoginAddress = false;
    if (!token) {
      for  (let address of validLoginAddresses) {
        if (window.location.pathname.indexOf(address) >= 0) {
          isValidLoginAddress = true;
        }
      }
      if (!isValidLoginAddress) {
        this.openLoginPage();
        return;
      }
      this.state.isLoaded = true;
    } else {
      API.get("/dashboard/")
        .then((response) => {
          if (response.data.profile) {
            localStorage.setItem("profile", JSON.stringify(response.data.profile));
          }
          this.setState({isLoaded: true});
        })
        .catch((error) => {
          // token is not valid
          localStorage.removeItem('token');
          this.openLoginPage();
        });
    }
  }
  openLoginPage = () => {
    let liveUrl = AccountService.returnUrl();
    window.open('/login', "_self");
  }
  render() {
    const { isLoaded } = this.state;
    return (
      <React.Fragment>
      {isLoaded &&
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            <BrowserRouter>
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password/:id" component={ResetPassword} />
                <Route render={() =>
                  <div className="App">
                    <Header></Header>
                    <Content></Content>
                    <Footer></Footer>
                  </div>
                } />
              </Switch>
            </BrowserRouter>
          </MuiThemeProvider>
        }
      </React.Fragment>
    );
  }
}

export default App;
